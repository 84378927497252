<template>
  <div class="container">
    <Back-to-top />
    <!-- Barre de navigation -->
    <navbar/>
    <div class="blocLogo">
      <h2>Politique de confidentialité</h2>
      <svg class="svg_icon" width="100px" style="padding: 0 !important" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 413.000000 414.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,414.000000) scale(0.100000,-0.100000)" fill="#442765" stroke="none">
          <path d="M1815 3475 c-300 -54 -593 -210 -801 -426 -196 -205 -312 -412 -381 -682 -23 -89 -26 -128 -30 -310 -6 -238 6 -329 63 -504 45 -140 93 -240 169 -352 232 -347 547 -557 968 -648 116 -25 425 -25 552 0 455 92 843 383 1045 786 100 200 147 385 157 611 6 150 -14 344 -40 400 -14 30 -52 38 -76 16 -67 -60 -203 -76 -287 -33 -102 52 -147 132 -143 255 2 43 0 82 -4 88 -3 6 -34 14 -68 17 -185 20 -304 171 -268 339 14 62 5 72 -72 81 -125 15 -185 136 -125 250 24 44 24 47 8 67 -13 15 -44 26 -120 40 -122 24 -431 27 -547 5z m493 -86 c60 -10 72 -15 66 -28 -4 -9 -9 -39 -11 -68 -6 -61 13 -126 52 -177 27 -35 114 -86 147 -86 16 0 18 -9 18 -67 0 -160 139 -321 300 -348 l37 -6 5 -64 c16 -197 182 -338 379 -322 36 3 83 13 104 22 30 12 40 13 46 3 14 -23 22 -161 16 -307 -6 -173 -38 -316 -110 -485 -107 -252 -327 -501 -575 -650 -164 -99 -387 -169 -592 -185 -731 -59 -1370 440 -1496 1169 -13 73 -15 134 -11 265 6 181 25 284 82 445 114 317 379 611 690 764 153 76 273 110 490 140 53 8 288 -2 363 -15z"/>
          <path d="M1992 3301 c-56 -22 -76 -43 -67 -70 11 -37 37 -43 100 -21 70 24 85 36 85 64 0 48 -42 57 -118 27z"/>
          <path d="M1408 3149 c-31 -18 -21 -57 30 -115 49 -57 87 -70 112 -39 18 22 5 54 -43 106 -47 51 -73 63 -99 48z"/>
          <path d="M1897 3075 c-103 -36 -146 -148 -94 -240 33 -59 78 -85 147 -85 98 0 170 69 170 162 0 81 -53 152 -126 169 -49 11 -51 11 -97 -6z m119 -113 c22 -28 17 -76 -10 -101 -51 -48 -136 -14 -136 54 0 73 102 106 146 47z"/>
          <path d="M2286 2779 c-38 -65 -45 -99 -22 -119 30 -24 59 -7 98 55 52 85 48 125 -13 125 -22 0 -33 -11 -63 -61z"/>
          <path d="M1119 2803 c-185 -94 -182 -352 6 -439 119 -56 253 -14 320 101 94 159 -27 365 -215 365 -42 0 -71 -7 -111 -27z m187 -84 c77 -37 105 -136 61 -213 -29 -52 -77 -80 -137 -80 -138 0 -210 161 -117 263 49 53 123 64 193 30z"/>
          <path d="M2172 2470 c-67 -14 -142 -60 -185 -115 -60 -76 -80 -128 -81 -215 -1 -90 28 -162 95 -235 65 -71 126 -98 232 -103 85 -4 90 -3 162 32 80 40 116 77 162 167 23 45 27 67 28 134 0 67 -4 89 -27 135 -41 83 -90 135 -161 169 -69 34 -155 45 -225 31z m147 -91 c66 -20 114 -61 149 -129 31 -59 34 -71 29 -127 -11 -138 -117 -235 -257 -234 -157 0 -281 159 -241 308 13 46 61 115 102 145 58 44 147 59 218 37z"/>
          <path d="M1650 2404 c-35 -13 -68 -30 -72 -37 -15 -23 -8 -56 13 -68 38 -20 179 44 179 82 0 11 -37 49 -47 49 -5 -1 -37 -12 -73 -26z"/>
          <path d="M1062 2168 c-20 -20 -13 -60 21 -123 26 -48 37 -61 59 -63 22 -3 30 2 39 22 10 22 8 35 -16 88 -36 83 -71 108 -103 76z"/>
          <path d="M2950 2135 c-20 -24 -3 -57 54 -102 56 -45 94 -54 116 -28 20 24 3 57 -55 103 -56 44 -94 53 -115 27z"/>
          <path d="M803 2070 c-17 -7 -23 -18 -23 -40 0 -34 19 -46 117 -70 42 -11 55 -11 67 -1 19 16 21 55 4 68 -11 8 -74 32 -133 49 -5 1 -20 -1 -32 -6z"/>
          <path d="M1442 1967 c-60 -61 -69 -100 -27 -119 19 -9 29 -8 49 5 33 22 96 100 96 120 0 16 -36 47 -55 47 -6 0 -34 -24 -63 -53z"/>
          <path d="M2659 1930 c-36 -62 -40 -74 -31 -95 9 -19 19 -25 42 -25 25 0 35 9 70 63 55 86 51 127 -12 127 -23 0 -33 -10 -69 -70z"/>
          <path d="M2866 1794 c-12 -11 -16 -35 -16 -89 0 -97 25 -126 78 -89 19 13 22 24 22 93 0 46 -5 82 -12 89 -17 17 -54 15 -72 -4z"/>
          <path d="M913 1705 c-15 -11 -22 -31 -27 -85 -3 -41 -2 -79 4 -89 13 -25 45 -32 69 -14 18 12 21 25 21 91 0 42 -4 82 -8 88 -13 19 -39 23 -59 9z"/>
          <path d="M2090 1637 c-57 -33 -73 -64 -50 -93 19 -21 50 -17 105 15 69 39 81 55 67 86 -16 35 -55 32 -122 -8z"/>
          <path d="M1385 1593 c-66 -24 -105 -53 -139 -102 -81 -116 -73 -246 20 -350 109 -122 309 -124 413 -5 55 63 75 112 75 189 0 80 -29 148 -86 205 -69 70 -190 96 -283 63z m162 -88 c81 -34 117 -97 111 -191 -3 -52 -9 -70 -34 -101 -74 -93 -216 -103 -290 -21 -65 71 -73 177 -18 245 58 73 152 101 231 68z"/>
          <path d="M2648 1480 c-56 -17 -97 -51 -122 -102 -33 -66 -33 -110 0 -176 57 -116 204 -144 301 -59 94 83 95 211 0 294 -53 46 -117 62 -179 43z m97 -94 c36 -15 65 -59 65 -98 0 -38 -35 -85 -74 -98 -69 -22 -146 29 -146 98 0 38 29 83 63 97 41 18 50 18 92 1z"/>
          <path d="M1910 1312 c-22 -18 -22 -21 -10 -73 23 -97 35 -119 69 -119 47 0 56 28 35 108 -15 60 -42 102 -64 102 -4 0 -18 -8 -30 -18z"/>
          <path d="M2320 1110 c-14 -26 -1 -51 53 -107 41 -43 73 -53 95 -31 22 22 12 53 -34 106 -51 58 -94 70 -114 32z"/>
          <path d="M1802 1068 c-5 -7 -13 -48 -17 -90 -6 -74 -6 -77 19 -93 31 -20 64 -12 70 18 14 67 14 136 0 156 -17 23 -56 28 -72 9z"/>
          <path d="M2098 917 c-70 -27 -92 -47 -83 -76 12 -38 35 -41 110 -15 89 31 113 53 97 89 -15 32 -46 32 -124 2z"/>
        </g>
      </svg>

      <div>
        <h3 class="text-center">Google Analytics</h3>
        <p class="text-left">Permet d'analyser les statistiques de consultation de notre site de manière anonyme.<br>
          Pour plus de détails vous pouvez consulter cette<a class="linkMentions" href="https://support.google.com/analytics/answer/6004245?hl=fr">description</a>
        </p>
        <p class="text-left">Vous pouvez réactiver ou désactiver les cookies en cochant l'option qui vous convient :</p>
        <div style="display: inline-flex">
          <div class="yes text-center" >
            <a href="#" @click.prevent="EnableTracking">
              <svg xmlns="http://www.w3.org/2000/svg" width="25.000000pt" height="25.000000pt" viewBox="0 0 423.000000 424.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,424.000000) scale(0.100000,-0.100000)"
                   fill="#442765" stroke="none">
                  <path d="M3206 3129 c-195 -28 -450 -135 -660 -274 -307 -205 -611 -542 -819 -908 l-44 -78 -133 191 c-73 105 -138 193 -143 197 -6 3 -32 6 -59 5 -131 -3 -378 -194 -378 -293 0 -19 72 -130 246 -380 136 -195 258 -367 272 -382 45 -47 121 -78 193 -78 143 0 189 43 316 296 352 702 786 1297 1185 1627 95 79 106 89 91 87 -5 -1 -35 -5 -67 -10z"/>
                </g>
              </svg>
            </a>
          </div>
          <div class="no text-center">
            <a href="#" @click.prevent="disableTracking">
              <svg xmlns="http://www.w3.org/2000/svg" width="25.000000pt" height="25.000000pt" viewBox="0 0 404.000000 404.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,404.000000) scale(0.100000,-0.100000)" fill="#7c84b5" stroke="none">
                  <path d="M2985 3225 c-44 -19 -104 -52 -134 -73 -153 -105 -497 -454 -771 -782 -29 -36 -59 -71 -65 -78 -9 -10 -42 17 -156 134 -453 463 -659 648 -749 674 -74 21 -150 -38 -150 -115 0 -38 8 -54 72 -137 97 -125 220 -273 429 -514 96 -111 202 -235 236 -277 l63 -75 -73 -94 c-256 -328 -546 -731 -591 -823 -96 -192 -81 -348 40 -425 141 -89 316 -7 337 158 6 50 86 212 193 389 88 147 299 467 319 483 11 9 22 0 53 -42 81 -110 234 -340 360 -540 200 -319 250 -367 386 -376 59 -4 79 -1 118 18 67 31 123 96 143 165 29 98 6 170 -96 305 -74 98 -421 507 -583 688 -61 68 -112 127 -114 131 -8 24 420 521 611 709 l126 124 40 -7 c88 -15 161 48 212 185 26 68 21 118 -16 165 -56 75 -120 82 -240 30z"/>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
     </div> <!-- fin blocLogo -->
    <!-- FOOTERS -->
    <FooterFull/>
  </div> <!-- fin container -->
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import BackToTop from "@/components/BackToTop/BackToTop";
import FooterFull from "@/components/FooterFull";

export default {
  name: 'Cookies',
  components: {
    Navbar,
    BackToTop,
    FooterFull,
  },
  metaInfo() {
    return {
      meta: [
        { name: 'description', content: 'Valérie Peltier Coaching & Conseil - Notre politique de confidentialité.'}
      ]
    }
  },
  mounted() {
    this.$gtag.pageview({
      page_path: 'Accueil/Politique-de-confidentialité'
    });
  },
  methods: {
    disableTracking: function() {
      this.$gtag.optOut();
      localStorage.setItem("acceptation", "1")
    },
    EnableTracking: function() {
      this.$gtag.optIn();
      localStorage.setItem("acceptation", "1")
    },
  }
}
</script>

<style scoped lang="css">
@media screen and (max-width: 640px) {
  .svg_icon {
    width: 50px;
    height: 50px;
  }
}

/* Logo */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.linkMentions {
  padding-left: 1.5%;
  text-decoration: none;
  padding-bottom: 5px;
}

/* unvisited link */
.linkMentions:link {
  color: #442765;
}

/* visited link */
.linkMentions:visited {
  color: #442765;
}

/* mouse over link */
.linkMentions:hover {
  color: #7c84b5;
}

/* selected link */
.linkMentions:active {
  color: gray;
}

</style>
