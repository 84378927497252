import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Offres from '../views/Offres.vue'
import Particuliers from '../views/Particuliers.vue'
import Alacarte from '../views/particuliers/alacarte.vue'
import Transition from '../views/particuliers/transition.vue'
import Entreprises from '../views/Entreprises.vue'
import Coaching from '../views/entreprise/Coaching.vue'
import Conseil from '../views/entreprise/Conseil.vue'
import notFound from "../views/notFound";
import Testimonial from "@/views/Testimonial";
import Blog from "@/views/Blog";
import Whoami from "@/views/Whoami";
import Croyances from "@/views/Croyances";
import Videos from "@/views/Videos";
import Mentions from "@/views/Mentions";
import Cookies from "@/views/Cookies";
import Contact from "@/views/Contact";


Vue.use(VueRouter);

let router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: '⌂',
      component: Home,
      meta: {
        title: 'Accueil',
        crumbs: {
          label: '⌂',
          parent: '',
        },
      }
    },
    {
      path: "/Offres",
      name: 'Offres',
      component: Offres,
      meta: { title: 'Mon Offre',
        crumbs: {
          label: 'Mon Offre',
          parent: '⌂',
        },
      }
    },
    {
      path: "/Particuliers",
      name: 'Particuliers',
      component: Particuliers,
      meta: { title: 'Particuliers',
        crumbs: {
          label: 'Particuliers',
          parent: 'Offres',
        },
      }
    },
    {
      path: "/Particuliers/A-la-carte",
      name: 'Alacarte',
      component: Alacarte,
      meta: { title: 'Particuliers à la carte',
        crumbs: {
          label: 'à la carte',
          parent: 'Particuliers',
        },
      }
    },
    {
      path: "/Particuliers/Transition",
      name: 'Transition',
      component: Transition,
      meta: {
        title: 'Particuliers Transition',
        crumbs: {
          label: 'Transition',
          parent: 'Particuliers',
        },
      }
    },
    {
      path: "/Entreprises",
      name: 'Entreprises',
      component: Entreprises,
      meta: {
        title: 'Entreprises',
        crumbs: {
          label: 'Entreprises',
          parent: 'Offres',
        },
      }
    },
    {
      path: "/Entreprises/Coaching",
      name: 'Coaching',
      component: Coaching,
      meta: {
        title: 'Entreprises Coaching',
        crumbs: {
          label: 'Coaching',
          parent: 'Entreprises',
        },
      }
    },
    {
      path: "/Entreprises/Conseil",
      name: 'Conseil',
      component: Conseil,
      meta: {
        title: 'Entreprises Conseil',
        crumbs: {
          label: 'Conseil',
          parent: 'Entreprises',
        },
      }
    },
    {
      path: "/Témoignages",
      name: 'Témoignages',
      component: Testimonial,
      meta: {
        title: 'Témoignages',
        crumbs: {
          label: 'Témoignages',
          parent: 'Offres',
        },
      }
    },
    {
      path: "/Blog",
      name: 'Blog',
      component: Blog,
      meta: {
        title: 'Blog',
        crumbs: {
          label: 'Blog',
          parent: 'Offres',
        },
      }
    },
    {
      path: "/Qui-suis-je",
      name: 'Qui-suis-je',
      component: Whoami,
      meta: {
        title: 'Qui-suis-je',
        crumbs: {
          label: 'Qui-suis-je',
          parent: 'Offres',
        },
      }
    },
    {
      path: "/Mes-croyances",
      name: 'Croyances',
      component: Croyances,
      meta: {
        title: 'Croyances',
        crumbs: {
          label: 'Croyances',
          parent: 'Qui-suis-je',
        },
      }
    },
    {
      path: "/Videos",
      name: 'Videos',
      component: Videos,
      meta: {
        title: 'Videos',
        crumbs: {
          label: 'Videos',
          parent: 'Offres',
        },
      }
    },
    {
      path: "/Mentions-légales",
      name: 'Mentions',
      component: Mentions,
      meta: {
        title: 'Mentions légales',
        crumbs: {
          label: 'Mentions légales',
          parent: 'Offres',
        },
      }
    },
    {
      path: "/Contact",
      name: 'Contact',
      component: Contact,
      meta: {
        title: 'Contact',
        crumbs: {
          label: 'Contact',
          parent: 'Offres',
        },
      }
    },
    {
      path: "/Politique-de-confidentialité",
      name: 'Cookies',
      component: Cookies,
      meta: {
        title: 'Cookies',
        crumbs: {
          label: 'Cookies',
          parent: 'Offres',
        },
      }
    },
    // and finally the default route, when none of the above matches:
    { path: "*", name: '404', component: notFound }
   ],
   scrollBehavior (to, from, savedPosition) {
     if (savedPosition) {
       // Keep scroll position when using browser buttons
       return savedPosition
     }
     else {
       return { x: 0, y: 0 }
     }
   }
});

/* Renommage des balises meta title */
router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Error!!!!';
  next();
});


export default router
