<template>
  <transition name="back-to-top-fade">
    <div
      v-show="visible"
      class="vue-back-to-top"
      :style="`bottom:${bottom};right:${right};`"
      @click="backToTop"
    >
      <slot>
        <div class="default">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="25.7pt" height="23.6pt" viewBox="0 0 257.000000 236.000000"
                 preserveAspectRatio="xMidYMid meet" style="margin-top: 10%">
            <metadata>
            Created by potrace 1.16, written by Peter Selinger 2001-2019
            </metadata>
            <g transform="translate(0.000000,236.000000) scale(0.100000,-0.100000)"
               fill="#7C84B5" stroke="none">
            <path d="M1207 2280 c-21 -6 -52 -17 -69 -26 -33 -17 -860 -687 -910 -738
            -105 -105 -103 -273 6 -382 89 -88 217 -109 319 -52 24 14 189 144 368 289
            178 146 330 268 336 272 8 5 136 -93 353 -270 195 -159 362 -288 391 -302 95
            -43 214 -26 296 43 46 39 93 138 93 196 0 61 -22 133 -53 174 -34 44 -895 745
            -950 773 -51 26 -127 36 -180 23z"/>
            <path d="M1213 1309 c-23 -4 -55 -15 -71 -23 -36 -19 -836 -669 -905 -734
            -142 -138 -103 -364 78 -448 59 -28 140 -31 206 -9 31 11 146 98 388 295 189
            155 347 283 352 285 4 3 162 -121 351 -275 188 -154 363 -290 388 -302 33 -16
            63 -22 110 -22 117 0 207 59 255 165 23 53 27 71 22 124 -10 126 -10 127 -507
            533 -263 215 -465 373 -495 387 -60 27 -115 35 -172 24z"/>
            </g>
            </svg>
          </span>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BackToTop',
  props: {
    visibleoffset: {
      type: [String, Number],
      default: 600,
    },
    visibleoffsetbottom: {
      type: [String, Number],
      default: 0,
    },
    right: {
      type: String,
      default: '30px',
    },
    bottom: {
      type: String,
      default: '40px',
    },
    scrollFn: {
      type: Function,
      // eslint-disable-next-line no-unused-vars
      default: function (eventObject) {},
    }
  },
  data () {
    return {
      visible: false
    }
  },
  mounted () {
    window.smoothscroll = () => {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(window.smoothscroll);
        window.scrollTo(0, Math.floor(currentScroll - (currentScroll / 5)))
      }
    };
    window.addEventListener('scroll', this.catchScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.catchScroll)
  },
  methods: {
    /**
             * Catch window scroll event
             * @return {void}
             */
    catchScroll () {
      const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset);
      const pastBottomOffset = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - parseInt(this.visibleoffsetbottom);
      this.visible = parseInt(this.visibleoffsetbottom) > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset;
      this.scrollFn(this)
    },
    /**
             * The function who make the magics
             * @return {void}
             */
    backToTop () {
      window.smoothscroll();
      this.$emit('scrolled')
    }
  },
}
</script>

<style scoped>

    .back-to-top-fade-enter-active,
    .back-to-top-fade-leave-active {
        transition: opacity .7s;
    }
    .back-to-top-fade-enter,
    .back-to-top-fade-leave-to {
        opacity: 0;
    }

    .vue-back-to-top {
        cursor:pointer;
        position: fixed;
        z-index: 1000;
    }

    .vue-back-to-top .default {
        background-color: rgb(1,14,27);
        background-color: rgba(68, 39, 101, .4);
        border-radius: 5px;
        color: #ffffff;
        line-height: 30px;
        text-align: center;
        width: 45px;
    }

    .vue-back-to-top .default span{
        color:#ffffff;
    }

    .vue-back-to-top--is-footer {
        bottom: 50% !important;
        position: absolute;
        transform: translateY(50%);
    }
</style>
