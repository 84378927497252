<!--./assets/js/components/notFound.vue-->
<template>
  <div class="error">
    <div class="container">
      <div class="col-sm-12 ground-color text-center">
        <div class="errorPage">
          <div class="clip">
            <div class="shadow">
              <span
                  class="digit"
              >{{ selector3 }}</span>
            </div>
          </div>
          <div class="clip">
            <div class="shadow">
              <span
                  class="digit"
              >{{ selector2 }}</span>
            </div>
          </div>
          <div class="clip">
            <div class="shadow">
              <span
                  class="digit"
              >{{ selector1 }}</span>
            </div>
          </div>
        </div>
        <h2>Oouuups ! Page non trouvée</h2>

        <router-link class="underline-left" to="/">
          <img width="25%" src="../assets/illustrations/Logo_vpcc_300x304.png" alt="Valérie Peltier - Coaching & Conseil">
          <h3>Retour au site</h3>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      selector1: 0,
      selector2: 0,
      selector3: 0,
    }
  },
  created () {
    this.changeNum();
  },
  methods: {
    changeNum: function () {

      function randomNum()
      {
        return Math.floor(Math.random() * 9)+1;
      }

      let loop1, loop2, loop3, time=30, i=0;

      loop3 = setInterval(() => {
        if(i > 40)
        {
          clearInterval(loop3);
          this.selector3 = 4;
        }else
        {
          this.selector3 = randomNum();
          i++;
        }
      }, time);

      loop2 = setInterval(() => {
        if(i > 80)
        {
          clearInterval(loop2);
          this.selector2 = 0;
        }else
        {
          this.selector2 = randomNum();
          i++;
        }
      }, time);

      loop1 = setInterval(() => {
        if(i > 100)
        {
          clearInterval(loop1);
          this.selector1 = 4;
        }else
        {
          this.selector1 = randomNum();
          i++;
        }
      }, time);
    }
  },
}
</script>


<style scoped lang="css">
*
{
  font-family: 'PT Sans Caption', sans-serif, 'arial', 'Times New Roman';
}
.error .clip .shadow {
  height: 180px;
}
.error .clip:nth-of-type(2) .shadow {
  width: 130px;
}
.error .clip:nth-of-type(1) .shadow, .error .clip:nth-of-type(3) .shadow {
  width: 250px;
}
.error .digit {
  width: 150px;
  height: 150px;
  line-height: 150px;
  font-size: 120px;
  font-weight: bold;
}
.error h2  {
  font-size: 32px;
}
.error .errorPage {
  margin-top: 10%;
  position: relative;
  height: 250px;
  padding-top: 40px;
}
.error .errorPage .clip {
  display: inline-block;
  transform: skew(-45deg);
}
.error .clip .shadow {
  overflow: hidden;
}
.error .clip:nth-of-type(2) .shadow {
  overflow: hidden;
  position: relative;
  box-shadow: inset 20px 0 20px -15px rgba(150, 150, 150,0.8), 20px 0 20px -15px rgba(150, 150, 150,0.8);
}

.error .clip:nth-of-type(3) .shadow:after, .error .clip:nth-of-type(1) .shadow:after {
  content: "";
  position: absolute;
  right: -8px;
  bottom: 0px;
  z-index: 9999;
  height: 100%;
  width: 10px;
  background: linear-gradient(90deg, transparent, rgba(173,173,173, 0.8), transparent);
  border-radius: 50%;
}
.error .clip:nth-of-type(3) .shadow:after  {
  left: -8px;
}
.error .digit {
  position: relative;
  top: 8%;
  color: white;
  /*background: #ff3f3f;*/
  background: rgba(68,39,101, 0.6);
  border-radius: 50%;
  display: inline-block;
  transform: skew(45deg);
}
.error .clip:nth-of-type(2) .digit {
  left: -10%;
}
.error .clip:nth-of-type(1) .digit {
  right: -20%;
}
.error .clip:nth-of-type(3) .digit {
  left: -20%;
}
.error h2 {
  color: #A2A2A2;
  font-weight: bold;
  padding-bottom: 20px;
}


@media(max-width: 767px) {
  .error .clip .shadow {
    height: 100px;
  }
  .error .clip:nth-of-type(2) .shadow {
    width: 80px;
  }
  .error .clip:nth-of-type(1) .shadow, .error .clip:nth-of-type(3) .shadow   {
    width: 100px;
  }
  .error .digit {
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 52px;
  }
  .error h2  {
    font-size: 24px;
  }

  .error .errorPage
  {
    height: 150px;
  }
}
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.container {
/*  width: 100%;
  padding-right: 15px;
  padding-left: 15px;*/
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.col-sm-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.text-center {
  text-align: center !important;
}

</style>
