<template>
  <div class="container">
    <Back-to-top />
    <!-- Barre de navigation -->
    <navbar/>
    <div class="blocLogo">
      <h2>Vidéos</h2>
      <svg class="svg_icon" width="100px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path fill="#442765" d="m33.11 1h56a5.62 5.62 0 0 1 5.62 5.62v53.67a5.62 5.62 0 0 1 -5.62 5.62h-50.57v-8h48.17v-48.91h-49.59a15.44 15.44 0 0 0 -4.01-8zm-11.25 20.13a9.5 9.5 0 1 0 -9.5-9.5 9.5 9.5 0 0 0 9.5 9.5zm-16.57 10.87v18.35a7.32 7.32 0 0 0 5.9 7.18v37a4.51 4.51 0 1 0 9 0v-26.32a1.71 1.71 0 0 1 1.37-1.72 1.65 1.65 0 0 1 1.93 1.63v26.37a4.51 4.51 0 1 0 9 0v-58.84l8.46 7a4.25 4.25 0 0 0 5.33.06l12.49-9.84a4.37 4.37 0 0 0 1.23-5.68 4.25 4.25 0 0 0 -6.26-1.13l-9.98 7.84-10-8.25a4.23 4.23 0 0 0 -2.7-1h-18.45a7.32 7.32 0 0 0 -7.32 7.35z"/>
      </svg>
    </div> <!-- fin blocLogo -->
    <!-- Fil d'ariane  -->
    <crumbs nav-class="nav_crumbs" list-class="ul_crumbs" item-class="li_crumbs" link-class="a_crumbs" last-item-class="last_li_crumbs"></crumbs>

    <!-- VIDEOS width="520" height="315" -->
    <div class="boxes">
      <div class="box" v-for="(video) in videos" v-bind:key="video.id">
        <h6>{{ video.titre }}</h6>
        <!-- lecteur vidéo You tube -->
        <iframe
            :src="video.urlYT"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
      </div>
    </div>


    <!-- FIN VIDEOS -->

    <!-- FOOTERS -->
    <Footer/>
    <FooterFull/>
  </div> <!-- fin container -->
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import BackToTop from "@/components/BackToTop/BackToTop";
import Footer from "@/components/Footer";
import FooterFull from "@/components/FooterFull";

export default {
  name: 'Videos',
  components: {
    Navbar,
    BackToTop,
    Footer,
    FooterFull
  },
  mounted() {
    this.$gtag.pageview({
      page_path: 'Accueil/Vidéos'
    });
  },
  metaInfo() {
    return {
      meta: [
        { name: 'description', content: 'Venez découvrir ces vidéos présentant notre démarche de coaching & conseil'}
      ]
    }
  },
  data() {
    return {
      isPlaying: false,
      videoUrl: null,
      videos: [
        { id: 1, titre:"Episode 1 : le coaching", category: 'Coaching', url: '@/assets/videos/Episode_1_le_coaching.mp4', urlYT: 'https://www.youtube.com/embed/XfLhD3GcNr0'},
        { id: 2, titre:"Episode 2 : les sujets de coaching", category: 'Coaching', url: '@/assets/videos/Episode_2_les_sujets_de_coaching.mp4', urlYT:'https://www.youtube.com/embed/qdrOmB7OH_s' },
        { id: 3, titre:"Episode 3 : une séance de coaching", category: 'Coaching', url: '@/assets/videos/Episode_3_une_seances_de_coaching.mp4', urlYT: 'https://www.youtube.com/embed/K-ElXvXGUIg' },
        { id: 4, titre:"Episode 4 : 5 bonnes raisons d'appeler un coach", category: 'Confiance', url: '@/assets/videos/Episode_4_5_bonnes_raisons_d_appeler_un_coach.mp4', urlYT: 'https://www.youtube.com/embed/UYZq8uGrpO0'},
        { id: 5, titre:"Coaching de transition", category: 'Transition', url: '@/assets/videos/coaching_de_transition.mp4', urlYT: 'https://www.youtube.com/embed/vgqDhLZSuvk'},
        { id: 6, titre:"Missions en entreprise", category: 'Entreprise', url: '@/assets/videos/missions_en_entreprise.mp4', urlYT: 'https://www.youtube.com/embed/vjyhoA3x2yQ'},
      ]
    }
  },
}
</script>

<style scoped lang="scss">
/**/
@media screen and (max-width: 640px) {
  .svg_icon {
    width: 50px;
    height: 50px;
  }
}
/* Logo */
.text-center {
  text-align: center;
}

/**/
.p3 {
  padding: 3%;
}

.bleu {
  color: #7c84b5;
}

.titre_video {
  cursor: zoom-in;
}

/* Zone video */
.boxes {
  padding: .5vw;
  flex-flow: row wrap;
  display: flex;
}

.box {
  margin: .5vw;
  padding: .5vw;
  flex: 1 0 auto;
  width: 300px;

}
.box iframe {
  width: 100%;
  height: auto;
}

</style>
