// ./components/FooterFull.vue
<template>
  <!-- Footer -->
  <div class="">
    <!--Footer Wrapper-->
    <div class="Footer-wrapper">
      <!--Footer-->
      <div class="Footer">
        <!--Flexbox Grid-->
        <div class="Grid Grid--cols-3">
          <!--Flexbox Cell One-->
          <div class="Grid-cell">
            <!--Flexbox Footer Content One-->
            <div class="Footer-content-1">
              <router-link class="underline-left" to="/Particuliers">
                <h3>Particuliers</h3>
              </router-link>
              <router-link class="underline-left" to="/Particuliers/A-la-carte">
                <h3>A la carte</h3>
              </router-link>
              <router-link class="underline-left" to="/Particuliers/Transition">
                <h3>Transition</h3>
              </router-link>
            </div>
            <!--Flexbox Cell One-->
          </div>
          <!--Flexbox Cell Two-->
          <div class="Grid-cell">
            <!--Flexbox Footer Content Two-->
            <div class="Footer-content-2">
              <div class="getintouchcontent">
                <!-- Facebook -->
                <div class="icon-container">
                  <a href="https://www.facebook.com/pageFBvpcc">
                    <svg style="margin-top: 20%" width="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                  </a>
                </div>
                <!-- Linkedin -->
                <div class="icon-container">
                  <a href="https://www.linkedin.com/in/valeriepeltier" target="_blank">
                    <svg style="margin-top: 20%"  width="20pt" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                  </a>
                </div>
              </div>
            </div>
            <!--Flexbox Cell Two-->
          </div>
          <!--Flexbox Cell Three-->
          <div class="Grid-cell">
            <!--Flexbox Footer Content Three-->
            <div class="Footer-content-3">
              <router-link class="underline-left" to="/Entreprises">
                <h3>Entreprises</h3>
              </router-link>
              <router-link class="underline-left" to="/Entreprises/Coaching">
                <h3>Coaching</h3>
              </router-link>
              <router-link class="underline-left" to="/Entreprises/Conseil">
                <h3>Conseil</h3>
              </router-link>
            </div>
            <!--Flexbox Cell Three-->
          </div>
          <!--Flexbox Grid-->
        </div>
        <div class="Grid-cell">
          <div class="Footer-content">
            <!-- Qui suis-je -->
            <router-link class="underline-left" to="/Qui-suis-je">
              <h3>Qui suis-je ?</h3>
            </router-link>
            <!-- Qui suis-je -->
            <router-link class="underline-left" to="/Mes-croyances">
              <h3>Mes croyances</h3>
            </router-link>
            <!-- Vidéos      -->
            <router-link class="underline-left" to="/Videos">
              <h3>Vidéos</h3>
            </router-link>
            <!-- Témoignages -->
            <router-link class="underline-left" to="/Témoignages">
              <h3>Témoignages</h3>
            </router-link>
            <!-- Blog      -->
            <router-link class="underline-left" to="/Blog">
              <h3>Blog</h3>
            </router-link>
            <!-- Contact  -->
            <router-link class="underline-left" to="/Contact">
              <h3>Contact</h3>
            </router-link>
            <!-- Mentions légales -->
            <router-link to="/Mentions-légales">
              <h3>Mentions légales</h3>
            </router-link>
            <!-- Mentions légales -->
            <router-link to="/Politique-de-confidentialité">
              <h3>Politique de confidentialité</h3>
            </router-link>
          </div>
          <div class="Footer-content">
            <h6><small>Created by <a href="https://www.natalikod.fr" target="_blank">www.natalikod.fr</a></small></h6>
          </div>
          <div class="Footer-content">
            <img alt="logo France Relance" src="../assets/illustrations/Logo-vert-en-.png_medium.png">
          </div>
          </div>
        <!--Footer-->
      </div>
      <!--Footer Wrapper-->
    </div>
  </div>
</template>
<script>
export default {
  name: 'FooterFull'
}
</script>


<style scoped lang="css">
/*Universal Grid Styles*/
/*********************************************/
html,
body {
  margin: 0;
  padding: 0;
}

.Footer-content:nth-child(1) {
  padding-top: 1%;
}

.Footer-content:nth-child(2)>h6 {
  font-family: 'Montserrat', sans-serif !important;
  letter-spacing: 1px !important;
}

.Footer-content:nth-child(2)>h6>small>a {
  font-family: 'Montserrat', sans-serif !important;
  letter-spacing: 1px !important;
  color: gray;
}

.Footer-content:nth-child(2)>h6>small>a:hover {
  color: black;
}

.Footer-content:nth-child(2) {
  padding: 0 0 0 0 !important;
}

.Footer-content:last-child {
  padding: 0 0 0 0 !important;
}

/*Universal Grid Element Behavoir*/
/*-------------------------------------------*/
.Grid {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.Grid-cell {
  /*Positioning*/
  position: relative;

  /*Display and Box Model*/
  flex: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/*-------------------------------------------*/
/*Individual Cell Behavoir for Screen Sizes*/
/*===========================================*/
/*Mobile First Individual Cell Sizing*/
/*-------------------------------------------*/
.Grid--cols-1 > .Grid-cell {
  flex: 0 0 100%;
}

.Grid--cols-2 > .Grid-cell {
  flex: 0 0 100%;
}

.Grid--cols-3 > .Grid-cell {
  flex: 0 0 100%;
}

.Grid--cols-4 > .Grid-cell {
  flex: 0 0 100%;
}

.Grid--cols-flex3 > .Grid-cell {
  flex: 0 0 100%;
}

.Grid--cols-flex4 > .Grid-cell {
  flex: 0 0 100%;
}

.Grid--cols-flex5 > .Grid-cell {
  flex: 0 0 100%;
}

.Grid--cols-inline > .Grid-cell {
  flex: 0 0 100%;
}

.alignHorizontal > .Grid-cell {
  display: flex;
  justify-content: center;
}

.alignVertical > .Grid-cell {
  display: flex;
  align-items: center;
}
/*-------------------------------------------*/
/*800px or More Screens Default Desktop Behavoir*/
/*-------------------------------------------*/
@media (min-width: 800px) {
  .Grid--cols-1 > .Grid-cell {
    flex: 0 0 100%;
  }
  .Grid--cols-2 > .Grid-cell {
    flex: 0 0 50%;
  }
  .Grid--cols-3 > .Grid-cell {
    flex: 0 0 33.33%;
  }
  .Grid--cols-4 > .Grid-cell {
    flex: 0 0 25%;
  }
  .Grid--cols-5 > .Grid-cell {
    flex: 0 0 20%;
  }
  .Grid--cols-6 > .Grid-cell {
    flex: 0 0 16.66%;
  }
  .Grid--cols-flex3 > .Grid-cell {
    flex: 0 0 50%;
  }
  .Grid--cols-flex4 > .Grid-cell {
    flex: 0 0 50%;
  }
  .Grid--cols-flex5 > .Grid-cell {
    flex: 0 0 50%;
  }
  /*Gutters*/
  .Grid--gutters {
    margin-left: -4em;
  }
  .Grid--gutters .Grid-cell {
    padding-left: 4em;
  }
}
/*-------------------------------------------*/
/*1000px or More Screens 2 per Row*/
/*-------------------------------------------*/
@media (min-width: 1000px) {
  .Grid--cols-flex3 > .Grid-cell {
    flex: 0 0 50%;
  }
  .Grid--cols-flex4 > .Grid-cell {
    flex: 0 0 50%;
  }
  .Grid--cols-flex5 > .Grid-cell {
    flex: 0 0 50%;
  }
}
/*-------------------------------------------*/
/*1200px or More Screens, Flex 3 per Row*/
/*-------------------------------------------*/
@media (min-width: 1200px) {
  .Grid--cols-flex3 > .Grid-cell {
    flex: 0 0 33.33%;
  }
  .Grid--cols-flex4 > .Grid-cell {
    flex: 0 0 33.3%;
  }
  .Grid--cols-flex5 > .Grid-cell {
    flex: 0 0 33.3%;
  }
  /*Gutters*/
  .Grid--gutters {
    margin-left: -8em;
  }
  .Grid--gutters .Grid-cell {
    padding-left: 8em;
  }
}
/*-------------------------------------------*/
/*1400px or More Screens, Flex 4 per Row*/
/*-------------------------------------------*/
@media (min-width: 1400px) {
  .Grid--cols-flex4 > .Grid-cell {
    flex: 0 0 25%;
  }
  .Grid--cols-flex5 > .Grid-cell {
    flex: 0 0 25%;
  }
}
/*-------------------------------------------*/
/*1600px or More Screens, Flex 5 per Row*/
/*-------------------------------------------*/
@media (min-width: 1600px) {
  .Grid--cols-flex5 > .Grid-cell {
    flex: 0 0 20%;
  }
  /*Gutters*/
  .Grid--gutters {
    margin-left: -12em;
  }
  .Grid--gutters .Grid-cell {
    padding-left: 12em;
  }
}

/*Footer Wrappers*/
/*-------------------------------------------*/
.Footer-wrapper {
  position: relative;
  /*Display and Box Model*/
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  /*Color*/
  background: #E8E8E8;
}

.Footer {
  position: relative;
  /*Display and Box Model*/
  width: 900px;
  margin: 0 auto;
}

@media (max-width: 900px) {
  .Footer {
    width: 250px;
  }
}

@media (max-width: 400px) {
  .Footer {
    width: 65%;
  }
}

/*-------------------------------------------*/
/*Footer Content Styles*/
/*-------------------------------------------*/
[class*="Footer-content"] {
  /*Positioning*/
  position: relative;
  padding: 1.5em 0 1.5em 0;
  /*Display and Box Model*/
  opacity: 1;
  /*Text*/
  text-align: center;
}

/*Text*/
[class*="Footer-content"] h2 {
  /*Display and Box Model*/
  margin-top: 0;
  margin-bottom: 5px;
  /*Text*/
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

[class*="Footer-content"] a {
  color: #442765;
  text-decoration: none;
}

[class*="Footer-content"] h3 {
  /*Display and Box Model*/
  position: relative;
  margin: 0;
  /*Text*/
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 5px;
  /*line-height: 50px;*/
  overflow: hidden;
}

[class*="Footer-content"] h3:hover {
  color: #7c84b5;
  letter-spacing: .2rem;
}

[class*="Footer-content"] .underline-middle h3:after {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}


/*-------------------------------------------*/
/*Footer Responsive Elements*/
/*-------------------------------------------*/
.Footer-mobile-line {
  /*Positioning*/
  position: absolute;
  left: 30%;
  bottom: 0;
  transform: translateZ(0);
  /*Display and Box Model*/
  width: 40%;
  height: 1px;
  margin: 0;
  padding: 0;
  /*Color*/
  background: #442765;
}

/*Tablet, Laptops, Desktops*/
@media (min-width: 800px) {
  [class*="Footer-mobile"] {
    visibility: hidden;
  }
  [class*="Footer-content"] {
    padding: 0 2em 0 2em;
  }
  .Footer-content-2 {
    /*Display and Box Model*/
    /*border-left: 1px solid black;*/

    /*Other*/
    -webkit-transition-delay: .5s;
    transition-delay: .5s;
  }
  .Footer-content-3 {
    /*Display and Box Model*/
    /*border-left: 1px solid black;*/

    /*Other*/
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
  }
}

.getintouchcontent {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  color: #442765;
}

.getintouchcontent a {
  color: #442765;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.icon-container {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.icon-container:hover {
  background-color: #442765;
}

.icon-container:hover a {
  color: #fff;
}
</style>


