import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import router from './router'
import VuePageTransition from 'vue-page-transition'
import Crumbs2 from '@phoenix91/vue2-crumbs';
import VueMeta from 'vue-meta'

Vue.use(VuePageTransition);
Vue.use(Crumbs2);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-1JM2MTZBXX" },
  params: {
    send_page_view: false,
    anonymize_ip: true
  },
  appName: 'Valérie PELTIER - Coaching & Conseil',
  pageTrackerScreenviewEnabled: true
}, router);


/*Vue.use(VueGtag, {
  bootstrap: false,
  config: {
    id: 'UA-1234567-8',
    params: {
      anonymize_ip: true
    }
  }
}, router)*/


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
