
<template>
  <div class="container">
<!--    <div class="container" @wheel="handleWheelDown">-->
    <div class="home">
      <img alt="Coaching de transition professionnelle pour les particuliers, coaching et mentoring de collaborateurs et managers, consulting en Digital/eCommerce." src="../assets/illustrations/Valerie-Peltier-Coaching-et-Conseil.gif">
      <p class="violet">Un coup de pouce pour passer de l'envie à l'action !</p>
      <div style="text-align: center">
        <a class="gris" href="tel:+33788851785">07 88 85 17 85</a>
        <br>
        <a class="gris" href="mailto:contact@valerie-peltier.com?subject=Valérie Peltier - Coaching et Conseil">contact@valerie-peltier.com</a>
      </div>
    </div>
    <router-link to="/Offres" id="entree">&#10154;</router-link>
    <img class="logoFR" alt="logo France Relance" src="../assets/illustrations/Logo-vert-en-.png_medium.png">
  </div>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  metaInfo() {
    return {
      meta: [
        { name: 'description', content: 'Coaching de transition professionnelle pour les particuliers, coaching et mentoring de collaborateurs et managers, consulting en Digital/eCommerce.'}
      ]
    }
  },
  mounted() {
    this.$gtag.pageview({
      page_path: 'Accueil'
    });
  },

/*  methods: {
    handleWheelDown() {
      window.addEventListener('wheel', event => {
        /!* scrolling down *!/
        if (event.deltaY > 0 && this.$route.name ==="Accueil") {
          this.$router.push({name:'Offres'})
        }
      })
    }
  },
  created () {
    window.addEventListener('wheel', this.handleWheelDown);
  },
  destroyed () {
    window.removeEventListener('wheel', this.handleWheelDown);
  },*/
}
</script>

<style scoped lang="css">

html,
body {
  height: 100vh;
  margin: 0;
}

.container {
  height: 100vh;
}

.home {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}

.gris {
  color: gray;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}

/* Souris slide 1 */
#entree {
  font-size: 2em;
  text-decoration: none;
  position: absolute;
  bottom: 20px;
  left: -webkit-calc(50% - 15px);
  left: calc(50% - 15px);
  /*z-index: 9999;*/
  cursor: pointer;
  animation: pulse-blue 3s infinite;
  padding: 1%;
  border-radius: 50%;

}

@keyframes pulse-blue {
  0% {
    transform: scale(0);
    box-shadow: 0 0 0 0 rgba(124, 132, 181, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(124, 132, 181, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(124, 132, 181, 0);
  }
}


/* fleche pour aller à offres */
#entree:after {
  content: '';
}

/* Souris slide 1 */
#scroll-down {
  width: 20px;
  height: 35px;
  position: absolute;
  bottom: 20px;
  left: -webkit-calc(50% - 15px);
  left: calc(50% - 15px);
  z-index: 9999;
  cursor: pointer;
  border-radius: 30px;
  border: 4px solid rgba(68,39,101, 0.6);
}
#scroll-down::after {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #442765;
  display: block;
  position: absolute;
  top: 5px;
  left: 8px;
  -webkit-animation: scroll-inner 1.5s;
  animation: scroll-inner 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function:ease;
  animation-timing-function:ease
}

@-webkit-keyframes scroll-inner {
  from {margin-top: 15%;}
  to {margin-top: 90%;}
}

@keyframes scroll-inner {
  from {margin-top: 15%;}
  to {margin-top: 90%;}
}
</style>
