<template>
  <div class="container">
    <Back-to-top />
    <!-- Barre de navigation -->
    <navbar/>
    <div class="blocLogo">
       <h2>Comment me contacter ?</h2>
       <svg class="svg_icon" width="100px" style="padding: 0 !important" viewBox="0 0 100 100" >
         <path fill="#442765" d="M 12.125 15 C 8.231006 15 5 18.231006 5 22.125 L 5 77.875 C 5 81.768994 8.231006 85 12.125 85 L 67.875 85 C 71.768994 85 75 81.768994 75 77.875 L 75 60.28125 L 94.125 41.1875 A 3.0003 3.0003 0 0 0 94.125 36.9375 L 85.0625 27.875 A 3.0003 3.0003 0 0 0 82.90625 27 A 3.0003 3.0003 0 0 0 80.8125 27.875 L 75 33.6875 L 75 22.125 C 75 18.231006 71.768994 15 67.875 15 L 12.125 15 z M 12.125 21 L 67.875 21 C 68.556226 21 69 21.443774 69 22.125 L 69 39.71875 L 51.875 56.875 A 3.0003 3.0003 0 0 0 51 59 L 51 68 A 3.0003 3.0003 0 0 0 54 71 L 63 71 A 3.0003 3.0003 0 0 0 65.125 70.125 L 69 66.25 L 69 77.875 C 69 78.556226 68.556226 79 67.875 79 L 12.125 79 C 11.443774 79 11 78.556226 11 77.875 L 11 22.125 C 11 21.443774 11.443774 21 12.125 21 z M 19.6875 29 A 3.0040663 3.0040663 0 1 0 20 35 L 60 35 A 3.0003 3.0003 0 1 0 60 29 L 20 29 A 3.0003 3.0003 0 0 0 19.6875 29 z M 82.9375 34.25 L 87.75 39.0625 L 84.53125 42.28125 L 79.71875 37.46875 L 82.9375 34.25 z M 19.6875 41 A 3.0040663 3.0040663 0 1 0 20 47 L 55 47 A 3.0003 3.0003 0 1 0 55 41 L 20 41 A 3.0003 3.0003 0 0 0 19.6875 41 z M 75.46875 41.71875 L 80.28125 46.53125 L 61.75 65 L 57 65 L 57 60.25 L 75.46875 41.71875 z M 19.6875 53 A 3.0040663 3.0040663 0 1 0 20 59 L 44 59 A 3.0003 3.0003 0 1 0 44 53 L 20 53 A 3.0003 3.0003 0 0 0 19.6875 53 z M 19.6875 65 A 3.0040663 3.0040663 0 1 0 20 71 L 44 71 A 3.0003 3.0003 0 1 0 44 65 L 20 65 A 3.0003 3.0003 0 0 0 19.6875 65 z "/>
       </svg>
    </div>
    <!-- Fil d'ariane  -->
    <crumbs nav-class="nav_crumbs" list-class="ul_crumbs" item-class="li_crumbs" link-class="a_crumbs" last-item-class="last_li_crumbs"></crumbs>
    <!--      -->
    <!-- Zone au dessus footer -->
    <div class="parentContact">
      <!-- ROW 1 -->
      <div>
        <a href="mailto:contact@valerie-peltier.com?subject=Valérie Peltier - Coaching et Conseil">
          <img class="imgContact" src="../assets/footer/email.png" alt="Valérie Peltier Coaching et Conseil Email" />
          <p><small>contact@valerie-peltier.com</small></p>
        </a>
      </div>
      <div>
        <a href="tel:+33788851785">
          <img class="imgContact" src="../assets/footer/phone.png" alt="Valérie Peltier Coaching et Conseil Téléphone" />
          <p><small>Tel : 07.88.85.17.85</small></p>
        </a>
      </div>
      <div>
        <a href="https://wa.me/33788851785">
          <img class="imgContact" src="../assets/footer/whatsApp.png" alt="Valérie Peltier Coaching et Conseil Whats App" />
          <p><small>WhatsApp : 07.88.85.17.85</small></p>
        </a>
      </div>
      <!-- ROW 2 -->
      <div>
        <a href="https://www.linkedin.com/in/valeriepeltier">
          <img class="imgContact" src="../assets/footer/linkedin.png" alt="Valérie Peltier Coaching et Conseil Email" />
          <p><small>Linkedin</small></p>
        </a>
      </div>
      <div>
        <a href="https://www.facebook.com/pageFBvpcc">
          <img class="imgContact" src="../assets/footer/facebook.png" alt="Valérie Peltier Coaching et Conseil Téléphone" />
          <p><small>Facebook</small></p>
        </a>
      </div>
      <div>
        <a href="skype:valeriepeltier?call">
          <img class="imgContact" src="../assets/footer/skype.png" alt="Valérie Peltier Coaching et Conseil Whats App" />
          <p><small>Skype : valeriepeltier</small></p>
        </a>
      </div>
    </div>
    <!-- -->

    <!-- FOOTER -->
    <FooterFull/>
  </div> <!-- fin container -->
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import BackToTop from "@/components/BackToTop/BackToTop";
import FooterFull from "@/components/FooterFull";

export default {
  name: 'Contact',
  components: {
    Navbar,
    BackToTop,
    FooterFull
  },
  metaInfo() {
    return {
      meta: [
        { name: 'description', content: 'Coach certifiée ICF et consultante, je suis à l\'écoute de vos besoins.'}
      ]
    }
  },
  mounted() {
    this.$gtag.pageview({
      page_path: 'Accueil/Contact'
    });
  },
}
</script>

<style scoped lang="css">
@media screen and (max-width: 640px) {
  .svg_icon {
    width: 50px;
    height: 50px;
  }
}

.parentContact {
  display: grid;
  /*grid-template-columns: repeat(3, 1fr);*/
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  padding-bottom: 2%;
  padding-top: 2%;
}

@media screen and (max-width: 640px) {
  .parentContact {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.imgContact {
  max-width:100%;
}

a {
  color: #818494;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .imgContact {
    width: auto !important;
  }
}


</style>
