<template>
  <div class="container">
    <Back-to-top />
    <!-- Barre de navigation -->
    <navbar/>
    <div class="blocLogo">
      <h2>Mentions légales</h2>
      <svg class="svg_icon" width="100px" style="padding: 0 !important" viewBox="0 0 100 100" >
        <path fill="#442765" d="M 12.125 15 C 8.231006 15 5 18.231006 5 22.125 L 5 77.875 C 5 81.768994 8.231006 85 12.125 85 L 67.875 85 C 71.768994 85 75 81.768994 75 77.875 L 75 60.28125 L 94.125 41.1875 A 3.0003 3.0003 0 0 0 94.125 36.9375 L 85.0625 27.875 A 3.0003 3.0003 0 0 0 82.90625 27 A 3.0003 3.0003 0 0 0 80.8125 27.875 L 75 33.6875 L 75 22.125 C 75 18.231006 71.768994 15 67.875 15 L 12.125 15 z M 12.125 21 L 67.875 21 C 68.556226 21 69 21.443774 69 22.125 L 69 39.71875 L 51.875 56.875 A 3.0003 3.0003 0 0 0 51 59 L 51 68 A 3.0003 3.0003 0 0 0 54 71 L 63 71 A 3.0003 3.0003 0 0 0 65.125 70.125 L 69 66.25 L 69 77.875 C 69 78.556226 68.556226 79 67.875 79 L 12.125 79 C 11.443774 79 11 78.556226 11 77.875 L 11 22.125 C 11 21.443774 11.443774 21 12.125 21 z M 19.6875 29 A 3.0040663 3.0040663 0 1 0 20 35 L 60 35 A 3.0003 3.0003 0 1 0 60 29 L 20 29 A 3.0003 3.0003 0 0 0 19.6875 29 z M 82.9375 34.25 L 87.75 39.0625 L 84.53125 42.28125 L 79.71875 37.46875 L 82.9375 34.25 z M 19.6875 41 A 3.0040663 3.0040663 0 1 0 20 47 L 55 47 A 3.0003 3.0003 0 1 0 55 41 L 20 41 A 3.0003 3.0003 0 0 0 19.6875 41 z M 75.46875 41.71875 L 80.28125 46.53125 L 61.75 65 L 57 65 L 57 60.25 L 75.46875 41.71875 z M 19.6875 53 A 3.0040663 3.0040663 0 1 0 20 59 L 44 59 A 3.0003 3.0003 0 1 0 44 53 L 20 53 A 3.0003 3.0003 0 0 0 19.6875 53 z M 19.6875 65 A 3.0040663 3.0040663 0 1 0 20 71 L 44 71 A 3.0003 3.0003 0 1 0 44 65 L 20 65 A 3.0003 3.0003 0 0 0 19.6875 65 z "/>
      </svg>

      <div>
        <h3 class="text-center">Identité</h3>
        <p class="text-left">Nom du site web : <a class="linkMentions" href="http://www.valerie-peltier.com">valerie-peltier.com</a></p>
        <p class="text-left">Adresse : <a class="linkMentions" href="http://www.valerie-peltier.com">http://www.valerie-peltier.com</a></p>
        <p class="text-left">Responsable de publication : Valérie PELTIER</p>
        <p class="text-left">Propriétaire : Valérie PELTIER</p>
      </div>
      <div>
        <h3>Personne morale</h3>
        <p class="text-left">VALERIE PELTIER COACHING & CONSEIL</p>
        <p class="text-left">SASU au capital social de 5 000 €</p>
        <p class="text-left">17 rue Popincourt 75011 Paris</p>
        <p class="text-left">RCS 887 834 901</p>
        <p class="text-left">N° TVA Intracommunautaire : FR 48 887 834 901</p>

        <!-- phone -->
        <p class="text-left">
          <svg width="12px" height="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="#442765" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" class=""></path>
          </svg>
          <a class="linkMentions" href="tel:+33788851785">07 88 85 17 85</a>
        </p>
        <!-- mail -->
        <p class="text-left">
          <svg width="12px" height="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="#442765" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" class=""></path>
          </svg>
          <a class="linkMentions" href="mailto:contact@valerie-peltier.com?subject=Contact via site valerie-peltier.com">contact@valerie-peltier.com</a>
        </p>
      </div>
      <div>
        <h3>Conception et réalisation du site</h3>
        <img src="../assets/illustrations/Logo_navbar 2.png" alt="Natalikod Créatice de site web">
        <p class="text-left">Nathalie BARENGHI</p>
        <p class="text-left">77430 CHAMPAGNE SUR SEINE</p>
        <p class="text-left">SIRET : 838 325 850 </p>
        <!-- phone -->
        <p class="text-left">
          <svg width="12px" height="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="#442765" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" class=""></path>
          </svg>
          <a class="linkMentions" href="tel:+33660908043">06 60 90 80 43</a>
        </p>
        <!-- mail -->
        <p class="text-left">
          <svg width="12px" height="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="#442765" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" class=""></path>
            </svg>
          <a class="linkMentions" href="mailto:contact@natalikod.fr?subject=Natalikod Créatice de site web">contact@natalikod.fr</a>
        </p>
        <p class="text-left">
          <!-- globe -->
          <svg width="12px" height="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="#442765" d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"></path>
          </svg>
          <a class="linkMentions" href="http://www.natalikod.fr" target="_blank">www.natalikod.fr</a>
        </p>
      </div>
      <div>
        <h3>Hébergement</h3>
        <p class="text-left">O2SWITCH</p>
        <p class="text-left">222-224 Boulevard Gustave Flaubert</p>
        <p class="text-left">63000 Clermont-Ferrand</p>
        <p class="text-left">SARL à associé unique au capital de 100 000 €</p>
        <p class="text-left">Siret : 510 909 80700024</p>
        <!-- Fax -->
        <p class="text-left">
        <svg width="12px" height="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="#442765" d="M480 160V77.25a32 32 0 0 0-9.38-22.63L425.37 9.37A32 32 0 0 0 402.75 0H160a32 32 0 0 0-32 32v448a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32zM288 432a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16zm0-128a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16zm128 128a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16zm0-128a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16zm0-112H192V64h160v48a16 16 0 0 0 16 16h48zM64 128H32a32 32 0 0 0-32 32v320a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32V160a32 32 0 0 0-32-32z" class=""></path>
        </svg>
          <a class="linkMentions" href="#">04 44 44 60 40</a>
        </p>
        <p class="text-left">
          <!-- globe -->
          <svg width="12px" height="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="#442765" d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"></path>
          </svg>
          <a class="linkMentions" href="https://www.strato.fr">www.o2switch.fr</a>
        </p>
      </div>
      <div>
        <h3>Conditions générales d'utilisation</h3>
        <p class="text-left">
          L’utilisation du présent site implique l’acceptation pleine et entière des conditions générales d’utilisation (C.G.U) décrites ci-dessous. Ces C.G.U sont susceptibles d’être modifiées ou complétées à tout moment.
        </p>
      </div>
      <div>
        <h3>Informations</h3>
        <p class="text-left">Les informations et documents du site sont présentés à titre indicatif, ne revêtent pas un caractère exhaustif, et ne peuvent engager la responsabilité du propriétaire du site.</p>
        <p class="text-left">Le propriétaire du site ne peut être tenu responsable des dommages directs et indirects consécutifs à l’accès au site.</p>
      </div>
      <div>
        <h3>Propriété intellectuelle</h3>
        <p class="text-left">Sauf mention contraire, tous les éléments accessibles sur le site (textes, images, graphismes, logo, icônes, sons, logiciels, etc.) restent la propriété exclusive de leurs auteurs, en ce qui concerne les droits de propriété intellectuelle ou les droits d’usage.</p>
        <p class="text-left">Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de l’auteur.</p>
        <p class="text-left">Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient est considérée comme constitutive d’une contrefaçon et peut être poursuivie en justice.</p>
        <p class="text-left">Les marques et logos reproduits sur le site sont déposés par les sociétés qui en sont propriétaires.</p>
      </div>
      <div>
        <h3>Liens</h3>
        <h4>Liens sortants</h4>
        <p class="text-left">Le propriétaire du site décline toute responsabilité et n’est pas engagé par le référencement via des liens hypertextes, de ressources tierces présentes sur le réseau Internet, tant en ce qui concerne leur contenu que leur pertinence</p>
        <h4>Liens entrants</h4>
        <p class="text-left">Le propriétaire du site autorise les liens hypertextes vers l’une des pages de ce site, à condition que ceux-ci ouvrent une nouvelle fenêtre et soient présentés de manière non équivoque afin d’éviter :</p>
        <ul class="text-left">
          <li>tout risque de confusion entre le site citant et le propriétaire du site</li>
          <li>ainsi que toute présentation tendancieuse, ou contraire aux lois en vigueur.</li>
        </ul>
        <p class="text-left">Le propriétaire du site se réserve le droit de demander la suppression d’un lien s’il estime que le site source ne respecte pas les règles ainsi définies.</p>
      </div>
      <div>
        <h3>Confidentialité</h3>
        <p class="text-left">Ce site ne collecte pas d'informations personnelles, et n'est pas assujetti à déclaration à la CNIL</p>
      </div>
      <div>
        <h3>Crédits</h3>
        <p class="text-left">Photos : <a class="linkMentions" target="_blank" href="https://www.stephanielucci.fr">Stéphanie Lucci</a></p>
      </div>
    </div> <!-- fin blocLogo -->
    <!-- FOOTERS -->
    <Footer/>
    <FooterFull/>
  </div> <!-- fin container -->
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import BackToTop from "@/components/BackToTop/BackToTop";
import Footer from "@/components/Footer";
import FooterFull from "@/components/FooterFull";

export default {
  name: 'Mentions',
  components: {
    Navbar,
    BackToTop,
    Footer,
    FooterFull
  },
  metaInfo() {
    return {
      meta: [
        { name: 'description', content: 'Valérie Peltier Coaching & Conseil - Nos mentions légales.'}
      ]
    }
  },
  mounted() {
    this.$gtag.pageview({
      page_path: 'Accueil/Mentions légales'
    });
  },

}
</script>

<style scoped lang="css">
@media screen and (max-width: 640px) {
  .svg_icon {
    width: 50px;
    height: 50px;
  }
}

/* Logo */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.linkMentions {
  padding-left: 1.5%;
  text-decoration: none;
  padding-bottom: 5px;
}

/* unvisited link */
.linkMentions:link {
  color: #442765;
}

/* visited link */
.linkMentions:visited {
  color: #442765;
}

/* mouse over link */
.linkMentions:hover {
  color: #7c84b5;
}

/* selected link */
.linkMentions:active {
  color: gray;
}

</style>
