<template>
  <div class="container" style="height: 100vh">
    <Back-to-top />
    <!-- Barre de navigation -->
    <navbar/>
    <!-- Page -->
      <h1 style="text-align: center"></h1>


    <div class="blocLogo">
      <h2>T&Eacute;MOIGNAGES</h2>
      <svg class="svg_icon" width="100px" style="padding: 0 !important" x="0px" y="0px" viewBox="0 0 100 100" xml:space="preserve">
        <g fill="#442765">
          <path d="M42.6,65.7c0.2-0.7,0.4-1.3,0.7-1.9c-3.1,0.4-6.1,0.5-7.6,0c-1.1-0.5-3.7-3.4-5.1-5c-1.4-1.5-2.6-2.8-3.8-4 c-2.1-2.1-3.7-3.5-6.7-4.2c-3.6-0.9-12.8-2-15.3,4C2,61,2.5,77.5,2.6,89.3c0,1,0.7,1.8,1.7,1.8h19.5c1.2,0,1.6-0.6,1.8-1.6 c0.1-0.6,0.7-11.9,1.1-19.8c1.9,1.8,3.7,3.3,5.8,3.9c1.9,0.6,4.2,0.8,6.6,0.8c2,0,4-0.2,5.8-0.4C42.8,71.8,42,68.8,42.6,65.7z"/>
          <circle cx="16" cy="35.2" r="11.8"/>
          <path d="M95.3,54.6c-2.6-6-11.7-5-15.4-4c-3,0.8-4.6,2.2-6.7,4.2c-1.2,1.1-2.5,2.4-3.8,4c-1.4,1.6-4.1,4.5-5.1,5 c-2.6,0.8-7.1-0.1-11.2-1c-2.8-0.6-5.6,1.1-6.2,3.9c-0.6,2.8,1.1,5.6,3.9,6.2c1.5,0.3,4.7,1.6,10.2,1.6c2.4,0,4.7-0.2,6.6-0.8 c2-0.7,3.9-2.1,5.8-3.9c0.4,8,1,19.2,1.1,19.8c0.1,1,0.6,1.6,1.7,1.6c1.2,0,17,0,19.5,0c1,0,1.7-0.8,1.7-1.8 C97.5,77.5,98,61,95.3,54.6z"/>
          <circle cx="84" cy="35.2" r="11.8"/>
          <path d="M39.1,31.1c-0.4,1.1-1.1,2.6-2.7,4.4c-1,1.1,0.2,2.8,1.6,2.3c2.9-1.1,6.2-2.6,9.2-4.1c0.9,0.1,1.9,0.2,2.8,0.2 c9.6,0,17.5-5.6,17.5-12.5c0-6.9-7.8-12.5-17.5-12.5s-17.5,5.6-17.5,12.5C32.5,25.3,35.1,28.8,39.1,31.1z M58.6,19 c1.3,0,2.3,1.1,2.3,2.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3C56.3,20.1,57.3,19,58.6,19z M50,19c1.3,0,2.3,1.1,2.3,2.3 c0,1.3-1,2.3-2.3,2.3s-2.3-1-2.3-2.3C47.7,20.1,48.7,19,50,19z M41.4,19c1.3,0,2.3,1.1,2.3,2.3c0,1.3-1,2.3-2.3,2.3 c-1.3,0-2.3-1-2.3-2.3C39,20.1,40.1,19,41.4,19z"/>
        </g>
      </svg>
    </div> <!-- fin blocLogo -->
    <!-- Fil d'ariane  -->
    <crumbs nav-class="nav_crumbs" list-class="ul_crumbs" item-class="li_crumbs" link-class="a_crumbs" last-item-class="last_li_crumbs"></crumbs>

      <div class="chat">
        <div class="chat-history">
          <ul>
            <li class="clearfix" v-for="(slide, key) in slides" :key="slide.id">
              <div v-if="key % 2 !== 0">
                <div class="message-data align-right">
                  <div>
                    <svg v-if="slide.sexe === 'M'" style="vertical-align:middle; padding: 7px; margin-right: 5px; background-color:lightgray; border-radius: 50%; border: solid lawngreen 2px" width="25px" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <defs>
                        <linearGradient id="a">
                          <stop stop-color="#ffeed6" offset="0"/>
                          <stop stop-color="#ffcd8b" offset="1"/>
                        </linearGradient>
                        <linearGradient id="h" x1="-211.94" x2="-170.85" y1="57.697" y2="26.983" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#664314" offset="0"/>
                          <stop stop-color="#a06b08" offset="1"/>
                        </linearGradient>
                        <linearGradient id="g" x1="-173" x2="-173.02" y1="98.031" y2="61.424" gradientUnits="userSpaceOnUse">
                          <stop offset="0"/>
                          <stop stop-color="#747474" offset="1"/>
                        </linearGradient>
                        <linearGradient id="f" x1="-170.22" x2="-171.64" y1="62.272" y2="86.38" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#d49c56" offset="0"/>
                          <stop stop-color="#d49c56" stop-opacity=".25806" offset="1"/>
                        </linearGradient>
                        <linearGradient id="e" x1="-167.79" x2="-162.97" y1="64.985" y2="5.3636" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#d49c56" offset="0"/>
                          <stop stop-color="#d49c56" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <linearGradient id="d" x1="-245.52" x2="-170.29" y1="16.796" y2="48.76" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#fff" offset="0"/>
                          <stop stop-color="#fff" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <radialGradient id="c" cx="-164.86" cy="72.644" r="15.009" gradientTransform="matrix(.1038 .081452 -.21402 .27274 -132.2 63.745)" gradientUnits="userSpaceOnUse" xlink:href="#a"/>
                        <radialGradient id="b" cx="-165.24" cy="33.695" r="21.607" gradientTransform="matrix(.7193 -.017199 .035132 1.4693 -44.059 -19.6)" gradientUnits="userSpaceOnUse" xlink:href="#a"/>
                      </defs>
                      <g transform="matrix(1.1403 0 0 1.1403 257.51 9.5835)" fill-rule="evenodd">
                        <path d="m-147.34 25.71c1.6025-11.001-4.4764-25.924-21.367-26.248-21.917-0.42056-25.993 15.37-24.882 27.159 1.3535 14.357 43.432 18.42 46.248-0.9104z" fill="url(#h)"/>
                        <path d="m-222.67 95.621c2.4491-6.7627 3.5335-19.316 11.964-23.508l39.107-8.3671 40.844 7.7638c7.1871 2.5802 10.584 16.564 14.02 24.487-2.2382 1.6068-4.1673 3.9864-6.829 4.5345h-92.679c-3.0097-1.1168-4.5341-3.1248-6.4286-4.9107z" fill="url(#g)" stroke="#000"/>
                        <path d="m-183.12 55.085c0.20847 5.0595-0.99076 7.9754-3.3611 12.264 9.229 16.963 33.15 8.2188 32.526-0.47819l-2.6468-14.464-26.518 2.6786z" fill="url(#c)" stroke="url(#f)"/>
                        <path d="m-149.38 27.913c-0.51037 7.997-0.7342 17.748-2.5254 25.745 0 0-8.1443 7.4029-12.69 9.4209s-5.8181 1.7443-10.102-0.06313-14.205-8.8526-14.205-8.8526c-4.2643-17.29-5.7232-35.412 13.258-45.064l5.62 6.6746c2.0794 2.4696 11.209 6.309 14.658 3.0161 2.7221-2.5989 4.3842 6.9312 5.9857 9.1234z" fill="url(#b)" stroke="url(#e)"/>
                        <path d="m-189.89 29.284c-0.7068 8.0159 0.46446 15.673 2.2396 23.852 4.8924 3.9526 8.6008 9.1673 18.813 7.0807 3.4966-0.79687 2.2001-2.4683 4.2084-3.4778-12.072 3.5866-14.041 1.0504-20.264-6.2528l-4.1078-23.873-0.88934 2.6714z" fill="url(#d)"/>
                      </g>
                    </svg>
                    <svg v-else style="vertical-align:middle; padding: 7px; margin-right: 5px; background-color:lightgray; border-radius: 50%; border: solid lawngreen 2px" width="25px" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <linearGradient id="p" x1="-1.9032" x2=".70863" y1="-.26174" y2=".59046">
                          <stop stop-color="#fff" offset="0"/>
                          <stop stop-color="#fff" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <linearGradient id="k" x1="1.1447" x2=".30422" y1="-.10789" y2=".99652">
                          <stop stop-color="#da8410" offset="0"/>
                          <stop stop-color="#c97402" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <linearGradient id="l" x1=".46895" x2=".46868" y1=".93204" y2="-.063107">
                          <stop offset="0"/>
                          <stop stop-color="#747474" offset="1"/>
                        </linearGradient>
                        <linearGradient id="n" x1=".5" x2=".61421" y1="1.009" y2="-.067449">
                          <stop stop-color="#d49c56" offset="0"/>
                          <stop stop-color="#d49c56" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <linearGradient id="o" x1=".32421" x2="1.0091" y1=".8782" y2=".25687">
                          <stop stop-color="#9f5f09" offset="0"/>
                          <stop stop-color="#9f5f09" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <linearGradient id="m" x1=".5" x2=".45108" y1=".20425" y2=".70333">
                          <stop stop-color="#d49c56" offset="0"/>
                          <stop stop-color="#d49c56" stop-opacity=".258" offset="1"/>
                        </linearGradient>
                        <linearGradient id="j" x1=".065804" x2=".57058" y1=".75524" y2=".39006">
                          <stop stop-color="#664314" offset="0"/>
                          <stop stop-color="#a06b08" offset="1"/>
                        </linearGradient>
                        <radialGradient id="r" cx="-165.24" cy="33.695" r="21.607" gradientTransform="matrix(.7193 -.017199 .035132 1.4693 -47.567 -19.6)" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#ffeed6" offset="0"/>
                          <stop stop-color="#ffcd8b" offset="1"/>
                        </radialGradient>
                        <radialGradient id="q" cx="-164.86" cy="72.644" r="15.009" gradientTransform="matrix(.1038 .081452 -.21402 .27274 -132.2 63.745)" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#ffeed6" offset="0"/>
                          <stop stop-color="#ffcd8b" offset="1"/>
                        </radialGradient>
                      </defs>
                      <g transform="matrix(1.1403 0 0 1.1403 257.51 9.5835)" fill-rule="evenodd">
                        <path d="m-219.05 48.495c7.466 0.071301 16.048-1.6429 17.761-9.0047 1.673-7.1925 0.80063-44.422 27.317-45.291 25.446-0.83414 32.09 20.451 34.278 32.107 7.0301 37.452-2.2129 62.67-55.476 47.491-6.0406-3.4875-9.6664-3.6518-13.773-9.1898 3.6136 0.098732 5.5046 0.3409 6.0469-3.2204-4.8116 0.19643-9.5198-0.24326-11.355-8.599 8.7717 1.2122 12.799-2.5829 16.541-8.3338-4.4415 6.9779-9.6709 10.893-21.34 4.0406z" fill="url(#j)"/>
                        <path d="m-200.79 41.749c3.8769-5.1869 5.0739-11.644 6.0714-19.241 2.6835 7.414 2.8936 14.806-1.9643 21.205" fill="url(#k)"/>
                        <path d="m-222.67 95.621c2.4491-6.7627 3.5335-15.808 11.964-20l39.107-11.875 40.844 11.272c7.1872 2.5802 10.584 13.056 14.02 20.98-2.2382 1.6068-4.1673 3.9864-6.829 4.5345h-92.679c-3.0097-1.1168-4.5341-3.1248-6.4286-4.9107z" fill="url(#l)" stroke="#000"/>
                        <path d="m-183.12 55.085c0.20847 5.0595 0.76318 9.7294-1.6072 14.018l5.7143 31.607 13.125-0.1786 10.179-33.661-0.8929-14.464-26.518 2.6786z" fill="url(#q)" stroke="url(#m)"/>
                        <path d="m-192.41 27.913c0.51038 7.997 0.73421 15.994 2.5254 23.991 0 0 8.1443 9.1569 12.69 11.175 4.5458 2.0179 5.8181 1.7443 10.102-0.063129 4.2834-1.8074 14.205-10.607 14.205-10.607 4.2643-17.29 5.7232-33.658-13.258-43.31l-13.763 20.456-0.3788-7.9549-3.5355 6.0609c-2.3928-2.9049-6.9847-1.9396-8.5863 0.25253z" fill="url(#r)" stroke="url(#n)"/>
                        <path d="m-144.18 47.106c-3.6067 8.5129-13.343 11.891-22.589 10.357 8.9573 7.0547 22.292 3.7482 25.446-8.3929" fill="url(#o)"/>
                        <path d="m-192.46 27.955c0.84372 7.8682 1.2766 15.6 3.0518 23.778 4.5232 4.691 13.769 14.926 19.256 11.806 3.4966-0.79687 4.489-1.9515 6.4973-2.961-12.072 3.5866-16.773-2.789-22.996-10.092l-4.1078-23.874-1.7015 1.3424z" fill="url(#p)"/>
                        <g fill="none" stroke="#000">
                          <path d="m-155.94 67.598l-9.8661 33.08"/>
                          <path d="m-185.24 67.77l6.0403 32.998"/>
                        </g>
                      </g>
                    </svg>
                    <span class="message-data-time" >{{ slide.firstname }}</span>&nbsp; &nbsp;
                    <span class="message-data-name" >{{ slide.name }}</span> &nbsp; &nbsp;
                    <span class="message-data-name" >({{ slide.position }})</span>
                  </div>
                </div>
                <div class="message other-message float-right">
                  {{ slide.text }}
                </div>
              </div>
              <!---->
              <div v-else>
                <div class="message-data">
                  <div>
                    <svg v-if="slide.sexe === 'M'" style="vertical-align:middle; padding: 7px; margin-right: 5px; background-color:lightgray; border-radius: 50%; border: solid lawngreen 2px" width="25px" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <defs>
                        <linearGradient id="a">
                          <stop stop-color="#ffeed6" offset="0"/>
                          <stop stop-color="#ffcd8b" offset="1"/>
                        </linearGradient>
                        <linearGradient id="h" x1="-211.94" x2="-170.85" y1="57.697" y2="26.983" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#664314" offset="0"/>
                          <stop stop-color="#a06b08" offset="1"/>
                        </linearGradient>
                        <linearGradient id="g" x1="-173" x2="-173.02" y1="98.031" y2="61.424" gradientUnits="userSpaceOnUse">
                          <stop offset="0"/>
                          <stop stop-color="#747474" offset="1"/>
                        </linearGradient>
                        <linearGradient id="f" x1="-170.22" x2="-171.64" y1="62.272" y2="86.38" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#d49c56" offset="0"/>
                          <stop stop-color="#d49c56" stop-opacity=".25806" offset="1"/>
                        </linearGradient>
                        <linearGradient id="e" x1="-167.79" x2="-162.97" y1="64.985" y2="5.3636" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#d49c56" offset="0"/>
                          <stop stop-color="#d49c56" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <linearGradient id="d" x1="-245.52" x2="-170.29" y1="16.796" y2="48.76" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#fff" offset="0"/>
                          <stop stop-color="#fff" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <radialGradient id="c" cx="-164.86" cy="72.644" r="15.009" gradientTransform="matrix(.1038 .081452 -.21402 .27274 -132.2 63.745)" gradientUnits="userSpaceOnUse" xlink:href="#a"/>
                        <radialGradient id="b" cx="-165.24" cy="33.695" r="21.607" gradientTransform="matrix(.7193 -.017199 .035132 1.4693 -44.059 -19.6)" gradientUnits="userSpaceOnUse" xlink:href="#a"/>
                      </defs>
                      <g transform="matrix(1.1403 0 0 1.1403 257.51 9.5835)" fill-rule="evenodd">
                        <path d="m-147.34 25.71c1.6025-11.001-4.4764-25.924-21.367-26.248-21.917-0.42056-25.993 15.37-24.882 27.159 1.3535 14.357 43.432 18.42 46.248-0.9104z" fill="url(#h)"/>
                        <path d="m-222.67 95.621c2.4491-6.7627 3.5335-19.316 11.964-23.508l39.107-8.3671 40.844 7.7638c7.1871 2.5802 10.584 16.564 14.02 24.487-2.2382 1.6068-4.1673 3.9864-6.829 4.5345h-92.679c-3.0097-1.1168-4.5341-3.1248-6.4286-4.9107z" fill="url(#g)" stroke="#000"/>
                        <path d="m-183.12 55.085c0.20847 5.0595-0.99076 7.9754-3.3611 12.264 9.229 16.963 33.15 8.2188 32.526-0.47819l-2.6468-14.464-26.518 2.6786z" fill="url(#c)" stroke="url(#f)"/>
                        <path d="m-149.38 27.913c-0.51037 7.997-0.7342 17.748-2.5254 25.745 0 0-8.1443 7.4029-12.69 9.4209s-5.8181 1.7443-10.102-0.06313-14.205-8.8526-14.205-8.8526c-4.2643-17.29-5.7232-35.412 13.258-45.064l5.62 6.6746c2.0794 2.4696 11.209 6.309 14.658 3.0161 2.7221-2.5989 4.3842 6.9312 5.9857 9.1234z" fill="url(#b)" stroke="url(#e)"/>
                        <path d="m-189.89 29.284c-0.7068 8.0159 0.46446 15.673 2.2396 23.852 4.8924 3.9526 8.6008 9.1673 18.813 7.0807 3.4966-0.79687 2.2001-2.4683 4.2084-3.4778-12.072 3.5866-14.041 1.0504-20.264-6.2528l-4.1078-23.873-0.88934 2.6714z" fill="url(#d)"/>
                      </g>
                    </svg>
                    <svg v-else style="vertical-align:middle; padding: 7px; margin-right: 5px; background-color:lightgray; border-radius: 50%; border: solid lawngreen 2px" width="25px" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <linearGradient id="p" x1="-1.9032" x2=".70863" y1="-.26174" y2=".59046">
                          <stop stop-color="#fff" offset="0"/>
                          <stop stop-color="#fff" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <linearGradient id="k" x1="1.1447" x2=".30422" y1="-.10789" y2=".99652">
                          <stop stop-color="#da8410" offset="0"/>
                          <stop stop-color="#c97402" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <linearGradient id="l" x1=".46895" x2=".46868" y1=".93204" y2="-.063107">
                          <stop offset="0"/>
                          <stop stop-color="#747474" offset="1"/>
                        </linearGradient>
                        <linearGradient id="n" x1=".5" x2=".61421" y1="1.009" y2="-.067449">
                          <stop stop-color="#d49c56" offset="0"/>
                          <stop stop-color="#d49c56" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <linearGradient id="o" x1=".32421" x2="1.0091" y1=".8782" y2=".25687">
                          <stop stop-color="#9f5f09" offset="0"/>
                          <stop stop-color="#9f5f09" stop-opacity="0" offset="1"/>
                        </linearGradient>
                        <linearGradient id="m" x1=".5" x2=".45108" y1=".20425" y2=".70333">
                          <stop stop-color="#d49c56" offset="0"/>
                          <stop stop-color="#d49c56" stop-opacity=".258" offset="1"/>
                        </linearGradient>
                        <linearGradient id="j" x1=".065804" x2=".57058" y1=".75524" y2=".39006">
                          <stop stop-color="#664314" offset="0"/>
                          <stop stop-color="#a06b08" offset="1"/>
                        </linearGradient>
                        <radialGradient id="r" cx="-165.24" cy="33.695" r="21.607" gradientTransform="matrix(.7193 -.017199 .035132 1.4693 -47.567 -19.6)" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#ffeed6" offset="0"/>
                          <stop stop-color="#ffcd8b" offset="1"/>
                        </radialGradient>
                        <radialGradient id="q" cx="-164.86" cy="72.644" r="15.009" gradientTransform="matrix(.1038 .081452 -.21402 .27274 -132.2 63.745)" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#ffeed6" offset="0"/>
                          <stop stop-color="#ffcd8b" offset="1"/>
                        </radialGradient>
                      </defs>
                      <g transform="matrix(1.1403 0 0 1.1403 257.51 9.5835)" fill-rule="evenodd">
                        <path d="m-219.05 48.495c7.466 0.071301 16.048-1.6429 17.761-9.0047 1.673-7.1925 0.80063-44.422 27.317-45.291 25.446-0.83414 32.09 20.451 34.278 32.107 7.0301 37.452-2.2129 62.67-55.476 47.491-6.0406-3.4875-9.6664-3.6518-13.773-9.1898 3.6136 0.098732 5.5046 0.3409 6.0469-3.2204-4.8116 0.19643-9.5198-0.24326-11.355-8.599 8.7717 1.2122 12.799-2.5829 16.541-8.3338-4.4415 6.9779-9.6709 10.893-21.34 4.0406z" fill="url(#j)"/>
                        <path d="m-200.79 41.749c3.8769-5.1869 5.0739-11.644 6.0714-19.241 2.6835 7.414 2.8936 14.806-1.9643 21.205" fill="url(#k)"/>
                        <path d="m-222.67 95.621c2.4491-6.7627 3.5335-15.808 11.964-20l39.107-11.875 40.844 11.272c7.1872 2.5802 10.584 13.056 14.02 20.98-2.2382 1.6068-4.1673 3.9864-6.829 4.5345h-92.679c-3.0097-1.1168-4.5341-3.1248-6.4286-4.9107z" fill="url(#l)" stroke="#000"/>
                        <path d="m-183.12 55.085c0.20847 5.0595 0.76318 9.7294-1.6072 14.018l5.7143 31.607 13.125-0.1786 10.179-33.661-0.8929-14.464-26.518 2.6786z" fill="url(#q)" stroke="url(#m)"/>
                        <path d="m-192.41 27.913c0.51038 7.997 0.73421 15.994 2.5254 23.991 0 0 8.1443 9.1569 12.69 11.175 4.5458 2.0179 5.8181 1.7443 10.102-0.063129 4.2834-1.8074 14.205-10.607 14.205-10.607 4.2643-17.29 5.7232-33.658-13.258-43.31l-13.763 20.456-0.3788-7.9549-3.5355 6.0609c-2.3928-2.9049-6.9847-1.9396-8.5863 0.25253z" fill="url(#r)" stroke="url(#n)"/>
                        <path d="m-144.18 47.106c-3.6067 8.5129-13.343 11.891-22.589 10.357 8.9573 7.0547 22.292 3.7482 25.446-8.3929" fill="url(#o)"/>
                        <path d="m-192.46 27.955c0.84372 7.8682 1.2766 15.6 3.0518 23.778 4.5232 4.691 13.769 14.926 19.256 11.806 3.4966-0.79687 4.489-1.9515 6.4973-2.961-12.072 3.5866-16.773-2.789-22.996-10.092l-4.1078-23.874-1.7015 1.3424z" fill="url(#p)"/>
                        <g fill="none" stroke="#000">
                          <path d="m-155.94 67.598l-9.8661 33.08"/>
                          <path d="m-185.24 67.77l6.0403 32.998"/>
                        </g>
                      </g>
                    </svg>
                    <span class="message-data-time">{{ slide.firstname }}</span> &nbsp; &nbsp;
                    <span class="message-data-name">{{ slide.name }}</span> &nbsp; &nbsp;
                    <span class="message-data-name" >({{ slide.position }})</span>
                  </div>
                </div>
                <div class="message my-message">
                  {{ slide.text }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

    <!-- FOOTERS -->
    <Footer/>
    <FooterFull/>
  </div> <!-- fin Testimonials -->
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from "@/components/Footer";
import FooterFull from "@/components/FooterFull";
import BackToTop from "@/components/BackToTop/BackToTop";

export default {
  name: 'Testimoniale',
  components: {
    Navbar,
    BackToTop,
    Footer,
    FooterFull
  },
  metaInfo() {
    return {
      meta: [
        { name: 'description', content: 'Coach certifiée ICF et consultante, découvrez ce que les autres disent de moi !'}
      ]
    }
  },
  mounted() {
    this.$gtag.pageview({
      page_path: 'Accueil/Témoignages'
    });
  },
  data () {
    return {
      slides: [
        {id:1, name: 'Benaut-Nougue', firstname: 'Sophie', sexe: 'F' ,position: 'Project Management Officer', text:'On pourrait dire que Valérie est une personne avec une très grande expérience managériale mais ce serait passer à côté de l’essentiel.\n' +
              'C’est avant tout quelqu’un qui dispose de qualités humaines assez rares. Son regard sur les « autres » est toujours celui de la curiosité, de la gaité et de la bienveillance. En miroir de son professionnalisme, elle a su entretenir une âme d’enfant qui lui permet de proposer une approche à la fois surprenante et pleine de bon sens y compris et peut être surtout sur les points de blocage. Valérie permet de nourrir ses rêves professionnels, d’y donner un sens puis de les réaliser : quel bel accompagnement !'},
        {id:2,name: '', firstname: 'Gwenaëlle',sexe: 'F'  ,position: 'Directrice de Production', text:'Valérie est arrivée dans ma vie par hasard. Déterminée à faire un travail sur moi et convaincue qu\'il n\'y a pas de coïncidence, je me suis dit que si l\'univers avait mis Valérie sur ma route c\'était pour une bonne raison et en effet, elle a pris une place importante.\n' +
              'Toujours disponible, très professionnelle, Valérie a su m\'accompagner dans mes réflexions. Sans jugement, toujours bienveillante et constructive elle m\'a amenée à me questionner profondément sur le sens des évènements et de mes émotions pour me ramener à la sérénité que j\'avais perdue.\n' +
              'Aujourd\'hui je me sens bien, je suis en paix, plus forte, sereine et confiante. Elle est magique !'},
        {id:3,name: 'Auguié', firstname: 'Sophie',sexe: 'F' ,position: 'Directrice de Projets Communication Interne', text:'Valérie est une formidable accompagnatrice : bienveillante, constructive, créative, ouverte, toujours de bonne humeur, elle a su m\'aider à définir clairement et précisément mon objectif, pour mieux me guider. Valérie a permis à mon projet d’être plus concret, plus impactant, plus réaliste. Toujours à l’écoute, posée, flexible, elle m\'a aidée à approfondir ma réflexion, à me poser les bonnes questions, à me remettre en question, à oser surmonter mes peurs, à écouter mes envies, tout en renforçant ma confiance en moi. Être coachée par Valérie fut un réel plaisir, une expérience enrichissante à titre professionnel mais aussi personnel. Merci encore pour tout ce que tu as su m\'apporter !'},
        {id:4,name: 'Wasserer-Diouri', firstname: 'Yasmina',sexe: 'F' ,position: 'Coach Professionnelle', text: 'J’ai eu la chance de faire ma formation de coach avec Valérie. Et je l’ai tout de suite « repérée ». Valérie est vive d’esprit, mais a aussi une finesse d’analyse qui lui permet de mener son questionnement de manière pertinente, avec les mots justes, au service de son coaché. (…) Son professionnalisme n’a d’égal que ses talents humains. Car Valérie est enthousiaste dans tout ce qu’elle entreprend ; elle est une optimiste à toute épreuve. Elle croit profondément en chaque personne. Elle cultive ce talent rare qui donne de l’attention à chacun, et qui redonne confiance. A ses côtés, tout devient possible…'},
        {id:5,name: 'Dardeau', firstname: 'Audrey',sexe: 'F' , position: 'Project Manager', text: 'L\'expérience de coaching vécue auprès de Valérie fût très positive et extrêmement riche. Très à l\'écoute, elle a cette capacité incroyable à comprendre et analyser les situations et à capter les éléments qui peuvent potentiellement être des obstacles dans l\'avancement des projets. Elle vise juste et fait en sorte que l\'on se pose (/ ou de poser) les bonnes questions. Valérie a été un réel catalyseur dans les démarches et réflexions de mon évolution professionnelle et je l\'en remercie.\n'},
        {id:6,name: 'Arami', firstname: 'Vincent',sexe: 'M' , position: 'Coach Professionnel', text: 'Valérie est d\'une douceur et d\'une intelligence qui lui donnent une dimension exceptionnelle. L\'avoir rencontrée et avoir la chance de travailler à ses côtés aujourd\'hui me permet d\'affirmer que bien au delà de son savoir-faire, son savoir-être vous garantira un accompagnement qui transformera votre vie.\n'},
        {id:7,name: 'Citerne', firstname: 'Guillaume',sexe: 'M' , position: 'E-Commerce senior manager', text: 'J’ai eu le plaisir de travailler avec valérie dans ses deux postes, chez Cartier et en tant que coach. J’ai été impressionné par ses qualités tant professionnelles qu’humaines. En tant que coach Valerie a une vraie valeur ajoutée s’appuyant sur une écoute active et une très bonne capacité à accompagner ses « coachés » à - comme elle dit - écrire le nouveau chapitre de leur vie professionnelle.'},
        {id:8,name: 'Dubois', firstname: 'Estelle',sexe: 'F' , position: 'Chef de Projets stratégiques', text: 'Grande altruiste toujours disponible et à l’écoute, Guide bienveillant dans nos moments de doute, Démêleuse hors pair de sacs de nœuds en tous genres, Reine de la ‘positive attitude’ au rire très communicatif, Terrienne mais tellement spirituelle… …et tout ça toujours avec efficacité, maîtrise, flexibilité et plaisir ! Merci Valérie pour ces 6 belles années de collaboration et confiance. Tes conseils continuent à raisonner même si les années passent. (Cartier, 2008-2014)\n'},
        {id:9,name: 'Degoulet', firstname: 'Marie',sexe: 'F' , position: 'Entrepreneur', text: 'J’ai eu la chance d’être dans l ‘équipe de Valérie pendant de nombreuses années. Valérie est un manager hors pair, une professionnelle de talent et confiance. Elle sait écouter, comprendre, faire réfléchir, grandir, inspirer et ce, toujours avec bienveillance, authenticité et justesse. Guide, sage, visionnaire, Valérie fait partie de ces personnes qui vous marquent à jamais lorsque vous avez la chance de croiser leur chemin. Encore aujourd’hui, j‘applique les enseignements précieux de nos échanges et de notre collaboration et je sais qu’ils me suivront tout au long de ma vie. Un grand Merci Valérie.\n'},
        {id:10,name: 'El-Hadi', firstname: 'Mohammed',sexe: 'M' , position: 'Directeur de transformation digitale', text: 'Croiser son chemin, m’aurait suffi ! Alors travailler sous sa direction fût non seulement un honneur, mais également une source d’inspiration ! Elle a eu à cœur de transmettre et de me faire grandir dans le respect de mon identité et de ma singularité. Je vous souhaite donc au minimum de croiser son chemin...\n'},
        {id:11,name: 'Cléré', firstname: 'Sandrine',sexe: 'F' , position: 'DRH', text: 'J\'ai sollicité à plusieurs reprises l\'expertise de Valérie en tant que coach sur des problématiques différentes. A chaque cas, Valérie a su apporter des réponses appropriées, a fait progresser les personnes ou a dénoué des situations difficiles. Je peux dire que chacune de ses interventions a été un succès. Valérie comprend très rapidement les problématiques et leurs enjeux, ce qui simplifie les échanges. Elle fait part d\'un excellent relationnel ce qui lui permet s\'adapter parfaitement à ses interlocuteurs. Enfin, j\'apprécie vraiment son regard très bienveillant qu\'elle porte sur les autres. Je recommande donc chaleureusement Valérie ! \n'},
        {id:12,name: 'Snyder', firstname: 'Trevor',sexe: 'M' , position: 'VP R&D', text: 'We have had the pleasure to work with Valérie to improve team relationships and dynamics, as well as to assist in improving individual performance. Valérie does an excellent job of identifying problematic areas then working with clients to develop concrete action plans to overcome those challenges. She has been extremely helpful and we will continue to work with her to improve individual and team performance. I am thankful to Valérie for her help and support and highly recommend her services.\n'},
        {id:13,name: 'Albouy-Blanc', firstname: 'Laure',sexe: 'F' , position: 'Sophrologue', text: 'Un grand merci à Valérie Peltier pour son coaching professionnel. Sans elle, notre projet n’aurait pas été si abouti, travaillé. Valérie a su nous motiver. Grâce à son accompagnement, j’ai su développer ma créativité et me lancer avec confiance dans notre nouveau projet. Je ne peux que la recommander !\n'},
        {id:14,name: 'B.', firstname: 'Emilie',sexe: 'F' , position: 'Conseil en Marketing et Expérience client', text: 'Valérie est une excellente coach, à la fois humaine et très professionnelle. Valérie sait s’adapter aux attentes de son interlocuteur et a un grand panel d’outils très simples à sa disposition. Valérie a une capacité d’écoute et d’empathie hors du commun et ce même en visio. Merci !\n'},
        {id:15,name: '', firstname: 'Christina',sexe: 'F' , position: 'co-fondatrice Epsisens', text: 'Un grand merci Valérie pour ton accompagnement en consulting d’une grande qualité. Ton professionnalisme a été précieux pour la construction, la réalisation et la mise en œuvre de notre entreprise. Gratitude.\n'},
        {id:16,name: 'P', firstname: 'Audrey',sexe: 'F' , position: 'Responsable RH', text: 'J’ai eu la chance d’avoir Valérie comme coach lors d’un accompagnement professionnel. Valerie est une coach bienveillante et brillante qui a su me montrer d’autres points de vue, m’ouvrir à de nouvelles perspectives, elle m’a aidée à travailler sur moi sans jamais juger. Mes séances avec elle ont toutes été très enrichissantes, j’ai énormément  grandi grâce à nos sessions. Encore un immense merci Valérie !\n'}
      ]
    }
  }
}
</script>

<style scoped lang="css">
@media screen and (max-width: 640px) {
  .svg_icon {
    width: 50px;
    height: 50px;
  }
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.chat .chat-history {
  /*padding: 30px 30px 20px;*/
  border-bottom: 2px solid white;
  /*overflow-y: scroll;*/
  height: auto;
}
.chat .chat-history .message-data {
  margin-bottom: 15px;
}
.chat .chat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
}
.chat .chat-history .message {
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  /*font-size: 16px;*/
  border-radius: 7px;
  margin-bottom: 30px;
  width: 90%;
  position: relative;
}
.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: rgba(68,39,101,0.5);
  border-width: 10px;
  margin-left: -10px;
}
.chat .chat-history .my-message {
  /*background: #442765;*/
  background: rgba(68,39,101,0.5);
}
.chat .chat-history .other-message {
  /*background: #7c84b5;*/
  background: rgba(124,132,181,0.5);
}
.chat .chat-history .other-message:after {
  border-bottom-color: rgba(124,132,181,0.5);
  left: 93%;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

</style>
