<template>
  <div class="container">
    <Back-to-top />
    <!-- Barre de navigation -->
    <navbar/>
    <!-- Bloc image Exapandable -->
    <expandable-image
        class="image"
        :src="require(`@/assets/illustrations/valerie_BlackWEB.jpg`)"
        alt="Valérie Peltier coaching et conseil"
        title="Valérie Peltier coaching et conseil"
    ></expandable-image>
    <!-- Fil d'ariane  -->
    <crumbs nav-class="nav_crumbs" list-class="ul_crumbs" item-class="li_crumbs" link-class="a_crumbs" last-item-class="last_li_crumbs"></crumbs>
    <!-- Bloc textes -->
    <div>
      <h2 class="text-center">Faisons connaissance</h2>
      <div>
        <p>J’habite à Champagne-sur-Seine (77) où je me délecte de randonnées à pieds et à vélo sur les bords de Seine, le long du Loing, en forêt, à travers champs.
          <br>J’exerce à Paris en présentiel mais partout dans le monde grâce à la video-conférence !</p>
        <p>J'aime les voyages, la découverte des autres, de leur cadre de vie, de leurs rythmes, de leur philosophie.</p>
        <p>J’aime la vie, les gens, le plaisir d'être en famille et entre amis, le partage de moments de convivialité, le rire et les bons petits plats.</p>
        <p>J’aime les choses simples, je m’extasie en regardant les salades pousser dans mon jardin, m’émeus au chant des oiseaux. Et je suis tout autant ébahie par la complexité des êtres humains, par leur personnalité si riche et suis fascinée par ce qui fait l’unicité de chacun d’entre nous.</p>
      </div>

      <h2 class="text-center">Mon parcours professionnel</h2>
      <div>
        <p>Après des études supérieures en finance, j'ai débuté ma carrière dans la gestion pour un cabinet de conseil, où j’ai évolué vers le métier de consultante. Une de mes missions m'a conduite chez Cartier et ces 10 jours de prestations se sont transformés… en 20 ans de carrière !</p>
        <p>J'ai exercé plusieurs fonctions de management et d'encadrement d'équipes internes et externes, en pilotant des projets d'envergure internationale, avec une spécialité en Digital et déploiement du eCommerce.</p>
        <p>Passionnée depuis toujours par le développement des individus et des organisations, cette aspiration s’est imposée à moi. J’ai entamé une transition professionnelle pour me former au métier du coaching.</p>
        <p>J’ai monté ma propre entreprise en 2020 pour concilier mes deux passions : le coaching et le conseil.</p>
        <p>J’accompagne, avec énergie et humilité, les particuliers et les entreprises dans leurs évolutions respectives.</p>
        <p>Je suis diplômée de l’école <a href="https://www.coachingways.fr" target="_blank">Coaching Ways</a> (Titre de Coach Professionnel RNCP Niveau 6) et membre de l’ICF (International Coaching Federation) de laquelle je suis certifiée au niveau <a href="https://www.credly.com/badges/46df0b2d-6684-4453-bbdd-e1bdf3d3fdd8/public_url" target="_blank">PCC</a> (Professional Certified Coach). J’ai plus de 1 000 heures de pratique de coaching.</p>
        <p>Pour plus de d&eacute;tails, voici mon profil&nbsp;<a href="https://fr.linkedin.com/in/valeriepeltier" target="_blank">Linkedin</a></p>
      </div>
    </div>
    <!-- fin affichage bloc texte -->
    <!-- FOOTERS -->
    <Footer/>
    <FooterFull/>
  </div> <!-- fin container -->
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import BackToTop from "@/components/BackToTop/BackToTop";
import Footer from "@/components/Footer";
import FooterFull from "@/components/FooterFull";
import ExpandableImage from "@/components/ExpandableImage/ExpandableImage";

export default {
  name: 'Whoami',
  components: {
    Navbar,
    ExpandableImage,
    BackToTop,
    Footer,
    FooterFull
  },
  metaInfo() {
    return {
      meta: [
        { name: 'description', content: 'Coach certifiée ICF et consultante, ma passion et mon expérience au service de vos projets.'}
      ]
    }
  },
  mounted() {
    this.$gtag.pageview({
      page_path: 'Accueil/Qui-suis-je'
    });
  },
}
</script>

<style scoped lang="scss">
.image {
  margin-top: 1.5% !important;
}

</style>
