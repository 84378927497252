<template>
  <div class="container particuliersIntro">
    <!-- Barre de navigation -->
    <navbar/>

    <!-- Page -->
    <!-- logo -->
    <div class="blocLogo">
        <h2>PARTICULIERS</h2>
      <svg class="svg_icon"  height="100px"  style="padding: 0 !important" viewBox="0 0 32 32" ><g><path fill="#442765" d="M24,21.334V25v1v1H8v-1v-1v-3.666C8,18.393,10.691,16,14,16h4C21.309,16,24,18.393,24,21.334z M16,15c2.757,0,5-2.243,5-5 s-2.243-5-5-5s-5,2.243-5,5S13.243,15,16,15z"/></g></svg>

      <h3 style="margin-top: 0">Choisissez l'approche qui vous convient</h3>
        <svg id="svg_logo" viewBox="0 0 1103.168 1011.38">

          <!-- SVG LOGO -->
          <g transform="matrix(.1 0 0 -.1 0 842)">
            <!--rond gauche-->
            <path id="rond1" fill-opacity="0.1" d="m2680 8350c-8-5-29-10-46-10s-44-4-60-9-49-14-74-21c-143-39-316-129-425-219-207-174-364-422-414-656-6-27-15-71-21-97-13-61-13-315 0-376 6-26 15-69 21-97 42-199 164-412 324-570 198-195 423-303 710-342 347-47 726 81 979 330 92 91 158 174 202 252 6 11 20 36 31 55 10 19 30 62 42 95 13 33 26 69 31 80 4 11 12 38 18 60s17 63 24 90c8 33 13 114 13 235s-5 202-13 235c-7 28-18 68-24 90-15 57-68 194-91 235-11 19-29 52-40 72-58 107-222 279-347 364-96 66-243 135-350 164-25 7-58 16-74 21s-46 9-67 9-41 5-44 10c-8 13-285 13-305 0z"/>
            <!--rond droit-->
            <path id="rond2" fill-opacity="0.1" d="m8390 8350c-8-5-27-10-42-10s-53-7-85-16-70-19-86-21c-15-3-30-9-33-14s-12-9-21-9c-29 0-218-101-288-153-237-177-390-408-463-697-24-94-26-124-26-280 0-158 2-185 27-282 64-246 158-409 339-584 207-200 426-301 726-335 219-24 483 28 681 137 150 82 290 202 389 333 66 88 79 110 128 211 63 133 74 166 104 318 13 69 13 350-1 410-25 112-32 140-50 193-28 82-112 243-161 308-65 87-215 232-298 288-127 85-291 156-420 181-30 6-68 14-85 18-51 12-319 15-335 4z"/>
            <!--infinity-->
            <path id="infinity" fill-opacity="0.1" d="m2525 5588c-190-18-330-45-560-108-52-14-129-40-147-50-10-6-22-10-28-10s-32-10-58-21c-26-12-63-28-82-36-34-15-179-87-230-114-73-40-197-122-295-195-274-205-481-425-667-709-66-100-213-378-238-450-4-11-12-31-18-45-30-72-57-152-82-240-7-25-16-56-20-70s-13-52-20-85c-6-33-15-78-20-100-8-38-23-135-36-240-10-81-7-483 4-570 13-97 29-203 36-230 2-11 10-47 16-80 7-33 16-71 20-85s13-45 20-70c122-435 359-839 685-1166 77-77 167-163 200-190 374-308 823-516 1280-593 33-5 94-16 135-23 50-9 166-13 345-13 253 0 349 7 480 35 22 5 69 14 105 20 36 7 76 15 90 20 14 4 45 13 70 20 79 22 224 70 242 80 10 6 22 10 28 10 32 0 311 141 449 228 86 54 249 169 271 192 3 3 30 25 60 49 30 25 714 703 1520 1507s1479 1473 1495 1486c322 259 774 318 1160 151 232-100 451-303 557-516 100-200 131-325 132-522 0-251-59-450-196-653-84-125-236-270-358-342-60-35-176-90-192-90-9 0-19-4-22-9s-18-11-33-14c-16-3-46-10-68-16s-94-18-159-26c-94-12-136-13-200-5-204 27-295 53-441 127-129 66-204 130-517 441-288 286-319 314-335 301-52-42-1113-1111-1113-1122 0-13 564-578 680-681 268-239 604-428 960-541 215-68 238-74 505-117 108-18 591-18 700 0 372 60 616 136 900 282 425 217 774 529 1044 932 149 223 274 489 341 728 7 25 16 56 20 70 12 36 45 185 55 240 47 277 49 676 5 925-27 153-33 183-40 210-5 17-14 50-21 75-19 72-69 223-79 242-6 10-10 22-10 28 0 13-27 74-93 210-264 546-711 995-1257 1262-80 39-155 75-167 80-27 9-64 24-95 37-23 10-127 44-183 60-35 11-178 44-250 59-134 28-374 52-522 52-282 0-679-66-898-150-11-5-36-13-55-20-34-12-53-19-102-39-48-20-256-122-317-156-98-55-274-178-390-273-59-49-733-714-1565-1546-1092-1092-1480-1474-1537-1513-231-158-506-230-766-198-65 8-136 20-158 25-22 6-53 15-70 19-86 24-241 102-325 164-467 346-610 947-347 1457 60 117 108 182 212 285 315 315 775 415 1203 260 192-69 264-125 632-491 179-178 330-324 335-324 6 0 264 254 575 565l565 565-338 338c-339 337-422 412-585 529-515 368-1200 552-1827 491z"/>
          </g>
          <!--way-->
          <svg id="way" x="19%" y="29%" xmlns="http://www.w3.org/2000/svg"
               width="102.000000pt" height="102.000000pt" viewBox="0 0 102.000000 102.000000"
               preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,102.000000) scale(0.100000,-0.100000)"
               fill="#442765" stroke="none">
              <path d="M428 865 c-43 -41 -78 -78 -78 -83 0 -5 25 -7 56 -4 l56 5 -3 -84 -4 -84 59 -3 58 -3 -3 83 -3 83 52 0 c29 -1 52 4 52 9 0 10 -150 156 -160 156 -3 -1 -40 -34 -82 -75z"/>
              <path d="M 40 715 c 0 -63 4 -115 8 -115 c 5 0 21 14 37 30 c 16 17 33 30 37 30 c 5 0 31 -22 57 -50 c 26 -27 52 -50 57 -50 c 5 0 24 16 43 35 l 34 35 l -29 32 c -16 17 -39 41 -51 53 c -30 29 -29 36 7 70 c 17 16 30 32 30 37 c 0 4 -52 8 -115 8 l -115 0 l 0 -115 z z"/>
              <path d="M 750 820 c 0 -11 51 -60 62 -60 c 5 0 8 -7 8 -15 c 0 -8 -5 -15 -11 -15 c -6 0 -16 -6 -22 -13 c -7 -7 -27 -29 -45 -49 l -33 -35 l 34 -37 c 19 -20 39 -36 44 -36 c 6 0 31 23 57 50 c 26 28 50 50 53 50 c 4 0 24 -17 45 -37 l 38 -37 l 0 122 l 0 122 l -115 0 c -67 0 -115 -4 -115 -10 z"/>
              <path d="M 328 503 c -38 -46 -48 -74 -48 -135 c 0 -36 6 -75 15 -92 c 8 -15 15 -37 15 -47 c 0 -10 4 -20 9 -24 c 13 -8 101 15 101 26 c 0 5 9 9 20 9 c 14 0 19 7 19 28 c 0 15 3 55 7 90 c 7 67 -1 95 -40 140 c -24 27 -76 30 -98 5 z"/>
              <path d="M 586 493 c -34 -34 -40 -59 -32 -136 c 4 -34 7 -73 7 -87 c 0 -20 9 -29 42 -42 c 23 -9 56 -19 74 -23 c 31 -6 32 -5 38 32 c 3 21 10 46 16 55 c 12 23 12 125 2 134 c 1 8 -4 17 -10 24 c 0 26 -52 71 -82 71 c -14 0 -37 -12 -52 -27 z"/>
              <path d="M 433 198 c -18 -6 -33 -15 -33 -19 c 0 -5 -11 -9 -25 -9 c -16 0 -25 -6 -25 -15 c 0 -8 4 -15 8 -15 c 5 0 14 -11 20 -25 c 10 -21 18 -25 56 -25 c 25 0 47 4 50 9 c 7 11 -5 111 -13 110 c -3 -1 -21 -6 -38 -11 z"/>
              <path d="M545 150 c0 -40 4 -53 22 -65 28 -20 63 -7 92 33 27 38 27 52 -1 52 13 1 -34 7 -48 15 -14 8 -34 14 -45 15 -17 0 -20 -7 -20 -50z"/>
            </g>
          </svg>
          <!--Papillon-->
          <svg id="papillon" x="70%" y="-13%" xmlns="http://www.w3.org/2000/svg"
               width="102.000000pt" viewBox="0 0 428.000000 428.000000"
               preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,428.000000) scale(0.100000,-0.100000)"
               fill="#7C84B5" stroke="none">
              <path d="M1037 3305 c-130 -36 -187 -149 -187 -371 0 -175 50 -423 115 -571
                                14 -30 23 -59 20 -63 -3 -5 -2 -10 3 -12 4 -1 35 -50 67 -107 66 -115 146
                                -209 206 -243 21 -12 39 -26 39 -30 0 -5 4 -7 9 -4 7 5 68 -11 80 -20 2 -2
                                -22 -31 -54 -66 -156 -171 -202 -307 -160 -473 39 -152 153 -310 257 -355 80
                                -35 181 -31 262 9 95 47 217 178 271 293 12 26 26 44 30 40 5 -4 5 -2 2 5 -4
                                6 14 56 39 110 42 87 48 95 54 73 5 -14 8 -35 9 -47 1 -12 5 -33 11 -48 9 -22
                                12 -24 30 -13 16 10 24 10 40 1 24 -15 25 -12 39 61 6 33 13 61 16 64 8 8 65
                                -129 58 -141 -3 -7 -2 -9 2 -5 5 5 27 -31 50 -80 69 -144 173 -260 280 -313
                                54 -26 74 -31 138 -31 55 1 89 7 126 23 103 44 221 212 257 367 22 92 14 186
                                -24 265 -27 59 -125 190 -170 231 -30 26 -28 31 21 45 91 27 162 86 238 197
                                105 156 189 356 226 543 10 49 20 88 23 86 3 -2 7 63 8 144 7 285 -45 402
                                -196 437 -99 24 -207 -9 -394 -119 -134 -79 -238 -164 -291 -239 -57 -80 -204
                                -346 -282 -510 -59 -124 -60 -126 -90 -120 -16 3 -32 6 -33 8 -2 1 17 40 42
                                86 24 46 45 85 46 88 0 3 7 28 16 55 49 163 84 286 84 300 0 8 -6 15 -14 15
                                -9 0 -28 -33 -47 -80 -18 -44 -37 -80 -42 -80 -4 0 -6 -4 -3 -8 4 -8 -34 -144
                                -72 -252 -10 -30 -25 -55 -32 -55 -8 0 -37 70 -76 187 -34 103 -72 209 -85
                                235 -25 48 -49 68 -49 38 0 -20 103 -378 111 -385 9 -9 81 -141 77 -144 -1 -2
                                -16 -5 -33 -8 -30 -6 -30 -5 -117 171 -104 210 -214 406 -271 480 -71 92 -301
                                248 -457 310 -93 36 -160 44 -223 26z"/>
            </g>
          </svg>

          <!--TEXTE A LA CARTE -->
          <g id="particuliers">
            <text x="25%" y="14%" text-anchor="middle" stroke="#442765" font-family="Montserrat, sans-serif"
                  style="font-size: 3em; font-weight: bold;">A LA CARTE</text>
            <text x="7%" y="48%" font-family="Montserrat, sans-serif" style="fill:#442765; font-size: 2.3em; font-weight: bold; letter-spacing: -2px">
              &#8226; coaching sur-mesure
              <tspan x="7%" y="55%" >&#8226; objectif de votre</tspan>
              <tspan x="9%" y="62%" >choix, de toute nature</tspan>
              <tspan x="7%" y="69%" >&#8226; nb libre de séances</tspan>
            </text>
          </g>
            <!-- pouce gauche -->
            <g transform="translate(0.000000,86.000000) scale(0.100000,-0.100000)" fill="#442765">
              <path class="pouce" id="pouceG" d="M2853.545-8027.715c-10-10-29.004-54.004-40.996-95.996c-21.006-70-27.002-79.004-73.008-114.004 c-27.998-20-60-47.002-70-59.004l-18.994-20.996l-17.998,30c-18.008,27.998-22.002,30-83.008,30 c-96.992,0-95.996,2.002-95.996-255.996v-213.008l25.996-20c51.006-40,148.008-22.998,165,28.008 c3.008,7.998,25,3.994,73.008-14.004c82.998-30.996,158.994-40,261.992-30c70,5.996,81.006,10,111.006,40 c17.998,17.998,32.998,42.998,32.998,54.004c0,11.992,10,35,20.996,50.996c15,20,20,35.996,15,54.004 c-5,18.994-0.996,31.992,14.004,48.994c15,15,20,32.998,19.004,57.002c-2.002,19.004,2.998,47.002,10.996,62.998 c10,21.006,10.996,32.998,2.002,52.002c-16.006,35-49.004,45-144.004,45c-78.994,0-80,0-73.994,22.998 c30.996,104.004,10,212.998-48.008,247.998C2899.541-8002.715,2878.545-8002.715,2853.545-8027.715z M2933.545-8168.711 c0-27.002-9.004-75-20.996-107.002c-11.006-31.006-23.008-65-26.006-74.004c-5-15.996,6.006-17.998,122.998-18.994 c104.004-1.006,123.008-3.008,97.002-11.006c-52.998-17.002-55.996-60-5.996-78.994c30-11.006,25-20-20-35 c-40-13.008-52.002-62.002-17.998-71.006c28.994-7.998,25.996-17.002-11.006-33.994c-37.998-16.006-45.996-39.004-22.002-63.008 c9.004-8.994,13.008-20,9.004-23.994c-4.004-4.004-59.004-7.002-122.998-7.002c-107.002,0-124.004,2.998-192.002,30l-75,30 l-2.998,117.002c-2.002,108.994-1.006,117.002,17.002,122.998c10.996,2.998,32.998,20.996,47.998,39.004 c15.996,17.998,47.002,45.996,70,60.996c52.998,34.004,65.996,54.004,92.998,145l22.002,74.004l18.994-23.008 C2927.549-8112.715,2933.545-8135.713,2933.545-8168.711z M2571.543-8510.713l2.002-187.002h-25h-25v182.998 c0,101.006,2.998,187.002,7.002,191.006c3.994,3.994,13.994,5,22.998,3.994C2566.543-8322.715,2568.545-8350.713,2571.543-8510.713 z"/>
            </g>
          <!-- TEXTE POUCE -->
          <g id="textePouce">
            <text x="38%" y="90%" font-family="Montserrat, sans-serif" style="fill:#818494; font-size: 2.3em; font-weight: bold; letter-spacing: -2px">
              Choisissez votre
              <tspan x="38%" y="95%">coup de pouce !</tspan>
            </text>
          </g>
          <!-- TEXTE TRANSITION -->

          <g id="entreprises">
            <text id="titre2" x="78%" y="11%" text-anchor="middle" stroke="#7C84B5" font-family="Montserrat, sans-serif"
                  style="font-size: 3em; font-weight: bold; fill:#7C84B5;">PROGRAMME</text>
            <text id="titre2_1" x="78%" y="18%" text-anchor="middle" stroke="#7C84B5" font-family="Montserrat, sans-serif"
                  style="font-size: 3em; font-weight: bold; fill:#7C84B5;">TRANSITION</text>
            <text id="text2" x="57%" y="48%" font-family="Montserrat, sans-serif" style="fill:#7C84B5; font-size: 2.3em; font-weight: bold; letter-spacing: -2px">
              &#8226; coaching dédié à la
              <tspan x="59%" y="55%" >transition pro ou perso</tspan>
              <tspan x="57%" y="62%" >&#8226; parcours structuré</tspan>
              <tspan x="59%" y="69%" >en 4 grandes étapes</tspan>
            </text>
          </g>
            <!-- pouce droit -->
            <g transform="translate(0.000000,86.000000) scale(0.100000,-0.100000)" fill="#7C84B5">
              <path class="pouce"  id="pouceD"  d="M8568.729-8027.714c-10-10-29.004-54.004-40.996-95.996c-21.006-70-27.002-79.004-73.008-114.004c-27.998-20-60-47.002-70-59.004l-18.994-20.996l-17.998,30c-18.008,27.998-22.002,30-83.008,30 c-96.992,0-95.996,2.002-95.996-255.996v-213.008l25.996-20c51.006-40,148.008-22.998,165,28.008 c3.008,7.998,25,3.994,73.008-14.004c82.998-30.996,158.994-40,261.992-30c70,5.996,81.006,10,111.006,40 c17.998,17.998,32.998,42.998,32.998,54.004c0,11.992,10,35,20.996,50.996c15,20,20,35.996,15,54.004 c-5,18.994-0.996,31.992,14.004,48.994c15,15,20,32.998,19.004,57.002c-2.002,19.004,2.998,47.002,10.996,62.998 c10,21.006,10.996,32.998,2.002,52.002c-16.006,35-49.004,45-144.004,45c-78.994,0-80,0-73.994,22.998 c30.996,104.004,10,212.998-48.008,247.998C8614.726-8002.714,8593.729-8002.714,8568.729-8027.714z M8648.729-8168.71 c0-27.002-9.004-75-20.996-107.002c-11.006-31.006-23.008-65-26.006-74.004c-5-15.996,6.006-17.998,122.998-18.994 c104.004-1.006,123.008-3.008,97.002-11.006c-52.998-17.002-55.996-60-5.996-78.994c30-11.006,25-20-20-35 c-40-13.008-52.002-62.002-17.998-71.006c28.994-7.998,25.996-17.002-11.006-33.994c-37.998-16.006-45.996-39.004-22.002-63.008 c9.004-8.994,13.008-20,9.004-23.994c-4.004-4.004-59.004-7.002-122.998-7.002c-107.002,0-124.004,2.998-192.002,30l-75,30 l-2.998,117.002c-2.002,108.994-1.006,117.002,17.002,122.998c10.996,2.998,32.998,20.996,47.998,39.004 c15.996,17.998,47.002,45.996,70,60.996c52.998,34.004,65.996,54.004,92.998,145l22.002,74.004l18.994-23.008 C8642.733-8112.714,8648.729-8135.712,8648.729-8168.71z M8286.728-8510.712l2.002-187.002h-25h-25v182.998 c0,101.006,2.998,187.002,7.002,191.006c3.994,3.994,13.994,5,22.998,3.994C8281.728-8322.714,8283.729-8350.712,8286.728-8510.712 z"/>
            </g>

          <!-- Routes-->
          <router-link to="/Particuliers/A-la-carte" >
              <rect x="0" y="0" width="551" height="1011.38" fill="transparent" />
          </router-link>
          <router-link to="/Particuliers/Transition">
            <rect x="551.5" y="0" width="552" height="1011.38" fill="transparent"/>
          </router-link>

        </svg> <!--fin logo-->
      <img class="logoFR" alt="logo France Relance" src="../assets/illustrations/Logo-vert-en-.png_medium.png">

    </div> <!-- fin container -->
  </div> <!-- fin Particuliers -->
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'Particuliers',
  components: {
    Navbar
  },
  metaInfo() {
    return {
      meta: [
        { name: 'description', content: 'Coaching de développement personnel et professionnel, spécialisé dans l\'accompagnement à la transition professionnelle.'}
      ]
    }
  },
  mounted() {
    this.$gtag.pageview({
      page_path: 'Particuliers'
    });
  },
}
</script>

<style scoped lang="scss">
/**/
@media screen and (max-width: 640px) {
  .svg_icon {
    width: 50px;
    height: 50px;
  }
}

@media screen and (min-width: 768px) {
  #svg_logo {
    width: 50% !important;
    margin: 0 !important;
  }
}

@media screen and (min-width: 1024px) {
  #svg_logo {
    width: 50% !important;
  }
}

/* ipad pro */
@media screen and (min-width: 1280px) {
  #svg_logo {
    width: 50% !important;
  }
}

/**/
@keyframes slide {
  0% {
    opacity: 1;
    left: 100%;
  }
  100% {
    opacity: 1;
  }
}

/* SVG Logo */
svg { fill: #442765; padding: 3%; color: rgb(124, 132, 181);}

#rond1, #rond2, #infinity, #particuliers, #entreprises, .pouce, #textePouce, #papillon, #way {
  animation: slideIn 750ms cubic-bezier(0.6, 0.95, 0.73, 1.19) both,
  fadeIn 150ms ease-in both;
}

/* ANIMATION */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-7em);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    transform: rotateX(-90deg) translateY(20em);
  }
  to {
    transform: rotateX(0deg) translateY(0);
  }
}

#infinity { animation-delay: .5s }
/* particuliers */
#rond1 { animation-delay: 2s }
#particuliers, #way { animation-delay: 2.5s }
#pouceG { animation-delay: 3s }
/* entreprises */
#rond2 { animation-delay: 3.5s }
#entreprises, #papillon { animation-delay: 4s }
#pouceD { animation-delay: 4.5s }
/* accroche */
#textePouce { animation-delay: 5s }

/* Logo */
#svg_logo {
  width: auto;
  min-height: 0;
  padding: 0 !important;
}

/* Bloc pouces */
#rowPouce {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .textePouce {
    font-size: 10px;
  }
}

.itemPouce {
  display: inline-block;
}

.svg_pouce {
  width: 40px;
}
</style>
