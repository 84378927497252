<template>
  <div class="container particulierstransition">
    <Back-to-top />
    <!-- Barre de navigation -->
    <navbar/>
    <!-- Page -->
    <div class="blocAlacarte">
        <!--Titres-->
        <div class="headerAlacarte">
          <h1>PARTICULIERS</h1>
          <h2 class="bleu">Programme Transition</h2>
          <!--papillon-->
          <div>
            <svg xmlns="http://www.w3.org/2000/svg"
                 height="100px"  viewBox="0 0 428.000000 428.000000"
                 preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,428.000000) scale(0.100000,-0.100000)"
                 fill="#7C84B5" stroke="none">
                <path d="M1037 3305 c-130 -36 -187 -149 -187 -371 0 -175 50 -423 115 -571 14 -30 23 -59 20 -63 -3 -5 -2 -10 3 -12 4 -1 35 -50 67 -107 66 -115 146 -209 206 -243 21 -12 39 -26 39 -30 0 -5 4 -7 9 -4 7 5 68 -11 80 -20 2 -2 -22 -31 -54 -66 -156 -171 -202 -307 -160 -473 39 -152 153 -310 257 -355 80 -35 181 -31 262 9 95 47 217 178 271 293 12 26 26 44 30 40 5 -4 5 -2 2 5 -4 6 14 56 39 110 42 87 48 95 54 73 5 -14 8 -35 9 -47 1 -12 5 -33 11 -48 9 -22 12 -24 30 -13 16 10 24 10 40 1 24 -15 25 -12 39 61 6 33 13 61 16 64 8 8 65 -129 58 -141 -3 -7 -2 -9 2 -5 5 5 27 -31 50 -80 69 -144 173 -260 280 -313 54 -26 74 -31 138 -31 55 1 89 7 126 23 103 44 221 212 257 367 22 92 14 186 -24 265 -27 59 -125 190 -170 231 -30 26 -28 31 21 45 91 27 162 86 238 197 105 156 189 356 226 543 10 49 20 88 23 86 3 -2 7 63 8 144 7 285 -45 402 -196 437 -99 24 -207 -9 -394 -119 -134 -79 -238 -164 -291 -239 -57 -80 -204 -346 -282 -510 -59 -124 -60 -126 -90 -120 -16 3 -32 6 -33 8 -2 1 17 40 42 86 24 46 45 85 46 88 0 3 7 28 16 55 49 163 84 286 84 300 0 8 -6 15 -14 15 -9 0 -28 -33 -47 -80 -18 -44 -37 -80 -42 -80 -4 0 -6 -4 -3 -8 4 -8 -34 -144 -72 -252 -10 -30 -25 -55 -32 -55 -8 0 -37 70 -76 187 -34 103 -72 209 -85 235 -25 48 -49 68 -49 38 0 -20 103 -378 111 -385 9 -9 81 -141 77 -144 -1 -2 -16 -5 -33 -8 -30 -6 -30 -5 -117 171 -104 210 -214 406 -271 480 -71 92 -301 248 -457 310 -93 36 -160 44 -223 26z"/>
              </g>
            </svg>
          </div>
        </div> <!-- fin header -->
        <!-- Fil d'ariane  -->
        <crumbs nav-class="nav_crumbs" list-class="ul_crumbs" item-class="li_crumbs" link-class="a_crumbs" last-item-class="last_li_crumbs"></crumbs>
        <!--Bloc description-->
        <hr class="style">
        <div class="bullet">
          <ul class="violet">
            <li>Un coaching en mode projet, structuré, pour écrire votre prochain chapitre pro ou perso</li>
            <li>6 à 10 séances max / 2 à 4 mois</li>
            <li>80€ par séance d'1h</li>
          </ul>
        </div>
        <hr class="style">
        <!--SVG progression programme-->
        <div style="text-align: center">
          <!--Ligne progression programme-->
          <svg id="transitionParcours" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="100%" viewBox="0 0 1060.11 300"
               xml:space="preserve">
    <g transform="translate(0.000000,163.000000) scale(0.100000,-0.100000)">
    <!--++++++++++++++++++-->
      <!-- Icone 1          -->
      <!--++++++++++++++++++-->
      <!-- tour 1 -->
    <path id="ico_1_tour_1" fill="#442765" d="M1377.5,641.699l-80-3.994l-112.002-112.002L1072.5,413.701V252.705v-160l117.998-117.002L1307.5-141.299h154.004h153.994
    l119.004,117.002L1852.5,92.705v160.996v160.996l-117.998,117.002l-119.004,117.002l-78.994-2.002
    C1493.496,645.703,1421.504,642.705,1377.5,641.699z M1718.496,516.699l103.008-102.998l6.992-152.998l7.002-152.002l-108.994-110
    l-108.008-110h-155H1307.5L1200.498-4.297L1092.5,103.701v149.004v148.994l107.002,107.998l107.002,109.004h155h153.994
    L1718.496,516.699z"/>
      <!-- tour 2 -->
      <path id="ico_1_tour_2" fill="#442765" d="M1219.502,490.703l-97.002-97.002v-140v-140l97.998-97.998l97.002-97.002h145.996h145l97.002,99.004l97.002,97.998v137.998
    v137.998l-97.002,97.998l-97.002,99.004H1462.5h-145L1219.502,490.703z M1679.502,476.699l92.998-92.998v-130v-130l-90-92.002
    l-90-92.998h-132.998H1327.5l-87.002,87.998l-85.996,87.002l-5,130.996l-5,131.006l92.002,95.996l90.996,97.002h130h130
    L1679.502,476.699z"/>
      <!-- main -->
      <path id="ico_1_main" fill="#442765" d="M1414.502,526.699c-5-7.002-12.998-22.998-16.006-35c-3.994-12.998-15-22.998-25-22.998
    c-32.998,0-40.996-22.002-40.996-109.004v-85l-27.998,27.002c-31.006,29.004-51.006,34.004-70,15
    c-14.004-13.994-16.006-53.994-2.998-62.998c5-2.998,23.994-42.002,40.996-87.998c70.996-190,70.996-189.004,202.002-185
    c87.002,2.998,92.002,3.994,112.002,30.996c18.994,26.006,20.996,44.004,23.994,194.004c4.004,195,1.006,212.998-32.998,212.998
    c-19.004,0-25,5-25,20.996c0,33.008-19.004,59.004-42.998,59.004c-12.002,0-27.998,9.004-35,20
    C1460.498,541.699,1430.498,544.697,1414.502,526.699z M1460.498,390.703c2.998-144.004,22.002-167.998,22.002-27.998
    c0,65,4.004,96.992,12.998,105c27.998,21.992,37.002-9.004,37.002-128.008c0-128.994,17.002-145,22.002-20.996
    c2.002,59.004,5.996,75,17.998,75s15.996-22.998,17.998-135c5-240-3.994-260-122.002-260c-96.992,0-112.998,12.998-155.996,127.002
    c-17.998,47.998-42.002,105-52.998,125.996c-15,27.002-17.998,40-10,47.998c12.998,13.008,45.996-16.992,77.998-71.992l20-34.004
    l5,125c4.004,105,7.998,125,20.996,127.998c22.002,4.004,29.004-25,29.004-125.996c0-52.998,4.004-81.006,10-77.002
    s10,60,10,134.004c0,70,2.998,130.996,7.002,135C1450.498,532.705,1457.5,503.701,1460.498,390.703z"/>
      <!--++++++++++++++++++-->
      <!-- Icone 2          -->
      <!--++++++++++++++++++-->
      <!-- tete -->
      <path id="ico_2_tete" fill="#442765" d="M4076.503,448.701c-61.006-24.004-85-120.996-43.008-172.998c66.006-85,199.004-34.004,199.004,75
    C4232.5,425.703,4149.501,478.701,4076.503,448.701z"/>
      <!-- corps -->
      <path id="ico_2_corps" fill="#442765" d="M3800.498,317.705c-10-5-17.998-21.006-17.998-34.004c0-25,15.996-40,110-102.998l55-37.998l5-152.002
    c4.004-121.006,7.998-155.01,20-162.002c22.998-15,282.998-13.008,297.998,1.992c7.998,8.008,12.002,58.008,12.002,159.004v147.002
    l90.996,62.002c78.008,54.004,90,65,87.002,87.002c-2.002,15.996-12.002,28.994-25,33.994c-17.998,6.006-40-3.994-112.998-50.996
    l-90.996-60h-112.002H4007.5l-89.004,60c-48.994,32.998-91.992,60-95,60C3820.498,328.701,3809.501,323.701,3800.498,317.705z"/>
      <!-- coeur 1 -->
      <path id="ico_2_coeur_1" fill="#442765" d="M3738.984,555.283c0-20,30-51.006,47.988-51.006c15,0,45,46.006,38.008,57.998
    c-3.008,5-24.004,10-45.996,10C3746.972,573.271,3738.984,569.277,3738.984,555.283z"/>
      <!-- coeur 2 -->
      <path id="ico_2_coeur_2" fill="#442765" d="M3864.843,449.599c-26.006-25.996-10-74.004,33.994-102.998c26.006-17.002,27.002-16.006,60,16.006
    c37.002,35.996,42.998,68.994,17.998,90c-12.998,10-19.004,10-35.996,0c-15-9.004-23.008-10-30.996-2.012
    C3895.839,464.599,3879.843,464.599,3864.843,449.599z M3907.832,421.601c4.004-6.006,13.008-5,25,2.002
    c13.008,9.004,23.008,9.004,30,1.992c14.004-13.994-15.996-53.994-40-53.994c-16.992,0-50,33.994-50,52.002
    C3872.832,434.599,3900.839,433.603,3907.832,421.601z"/>
      <!--++++++++++++++++++-->
      <!-- Icone 3          -->
      <!--++++++++++++++++++-->
      <!-- loupe -->
      <path id="ico_3_loupe" fill="#442765" d="M6669.501,518.701c-109.004-19.004-200-87.002-249.004-187.998c-30-61.006-32.998-74.004-32.998-162.998
    c0-88.008,2.998-101.006,32.002-157.002c20-39.004,28.994-69.004,25.996-82.998c-5-19.004-2.998-21.016,19.004-16.016
    c17.998,5,30,1.016,43.994-13.984c29.004-33.008,106.006-67.012,171.006-78.008c73.994-11.992,150.996,1.992,220,38.008
    l50.996,27.988l92.998-92.988c95-95,123.008-110,149.004-79.004c22.002,25.996,2.998,57.988-87.998,147.988l-87.998,88.008
    l30,62.002c25,47.998,31.992,77.002,33.994,137.002c5,90.996-7.002,137.002-50,210
    C6958.495,477.705,6811.503,542.705,6669.501,518.701z M6859.501,440.703c65-34.004,110-77.998,140-140
    c22.998-47.002,27.998-70,27.998-132.002c0-62.998-5-84.004-29.004-132.998c-83.994-165.01-280-215.01-432.998-110
    c-28.994,18.994-52.998,40-52.998,45.996c0,15,80.996,137.002,90.996,137.002c4.004,0,47.002-9.004,95-20
    c48.008-12.002,93.008-19.004,100-15.996c7.002,2.998,34.003,47.998,60,100c49.004,95,49.004,95.996,66.006,73.994
    c15-20.996,17.002-20.996,23.994-5c6.006,16.006-10,71.006-25.996,90c-10,12.002-100-22.998-100-40
    c0-10.996,7.002-13.994,25-10.996c14.004,2.998,25,2.002,25-2.002s-20-45.996-45-92.998l-45-84.004l-82.998,19.004
    c-45,10-90,20.996-99.004,23.994c-12.998,4.004-28.994-12.998-62.998-65.996c-25.996-39.004-50.996-67.998-55.996-65
    c-22.002,14.004-42.002,84.004-43.008,150c-1.992,137.002,71.006,242.002,203.008,292.002
    C6693.496,474.697,6807.5,466.699,6859.501,440.703z"/>
      <!-- rond -->
      <path id="ico_3_rond" fill="#442765" d="M7015.497,610.674c-23.994-20-27.998-44.004-12.002-67.002c17.002-22.998,53.008-27.998,72.002-8.994
    c17.002,16.992,15,53.994-3.994,71.992C7052.499,625.674,7036.503,626.67,7015.497,610.674z M7060.497,578.672
    c9.004-22.002-17.998-40-32.998-22.002c-5.996,9.004-9.004,20-5,25.996C7031.503,597.666,7054.501,594.678,7060.497,578.672z"/>
      <!-- etoiles  -->
      <path id="ico_3_star_1" fill="#442765" d="M6645.498,365.107c0-6.992,5-17.998,12.002-25c9.004-9.004,6.992-12.998-5-17.998
    c-9.004-2.998-17.002-12.002-17.002-20c0-17.002,12.002-17.998,27.998-2.002c9.004,9.004,15,8.008,25.996-7.998
    c18.008-23.994,35-10,19.004,16.006c-10,13.994-9.004,18.994,2.002,23.994c25,8.994,17.002,28.994-8.994,22.002
    c-16.006-4.004-26.006-0.996-30,9.004C6664.492,381.103,6645.498,383.115,6645.498,365.107z"/>
      <path id="ico_3_star_2" fill="#442765" d="M6645.537,15.361c0-7.998,5-17.998,11.006-21.992c7.988-5,5.996-10-5-16.006
    c-24.004-12.998-20-30.996,5.996-25c14.004,4.004,25.996,0,34.004-10.996c16.992-22.998,30.996-6.006,15.996,18.994
    c-10,16.006-10,20,2.998,25c7.998,4.004,15,12.002,15,20c0,17.002-12.002,18.008-28.994,2.002c-10-10.996-14.004-10-22.012,5
    C6662.539,33.369,6645.537,34.365,6645.537,15.361z"/>
      <path id="ico_3_star_3" fill="#442765" d="M6868.495,112.402c0-2.998,4.004-13.994,10-23.994c7.012-13.008,6.006-19.004-5-24.004
    c-25.996-9.004-16.992-29.004,9.004-22.002c14.004,2.998,28.008,2.002,30.996-3.994c13.008-20,27.012-10,21.006,15
    c-3.994,13.994-2.002,25,3.994,25c5,0,10,6.992,10,15c0,17.988-11.992,18.994-27.988,2.988c-9.004-8.994-13.008-6.992-18.008,5
    C6896.503,117.402,6868.495,126.396,6868.495,112.402z"/>
      <path id="ico_3_star_4" fill="#442765" d="M6492.5,270.41c0-4.004,5.996-14.004,12.998-21.006c10-10,9.004-13.994-5-21.992
    c-27.002-15-22.002-32.002,6.006-25c13.994,2.998,27.998,1.992,30.996-4.004c12.998-20,27.002-10,20.996,15
    c-3.994,14.004-1.992,25,4.004,25c5,0,10,7.002,10,15c0,10.996-7.002,14.004-25,9.004c-15-4.004-25-2.002-25,5
    c0,5.996-7.002,10.996-15,10.996S6492.5,274.404,6492.5,270.41z"/>
      <!--++++++++++++++++++-->
      <!-- Icone 4          -->
      <!--++++++++++++++++++-->
    <path id="ico_4_tete" fill="#442765" d="M9284.501,575.703c-47.998-50-15-127.002,52.998-127.002c45,0,75,29.004,75,72.998
    C9412.499,589.697,9329.501,624.697,9284.501,575.703z"/>
        <!-- corps -->
        <path id="ico_4_corps" fill="#442765" d="M9245.497,402.705c-55.996-28.008-92.002-64.004-122.998-125c-22.998-44.004-17.002-69.004,15-69.004
    c17.002,0,30.996,12.998,54.004,50c16.992,27.998,33.994,50,36.992,50c4.004,0,4.004-30,1.006-65.996l-7.002-67.002l74.004-76.006
    c66.992-68.994,75.996-81.992,90-140.996c23.994-95,31.992-110,60-110c45.996,0,50,20.996,25,122.002
    c-19.004,80-28.008,97.998-66.006,138.994c-45.996,50-48.994,61.006-32.002,139.004l11.006,52.002l32.002-30
    c70-66.006,125.996-77.998,125.996-27.998c0,17.998-9.004,30-27.998,40c-15,7.998-52.998,41.992-84.004,75
    c-40,43.994-62.998,60.996-80.996,60.996c-14.004,0-31.006,2.002-39.004,5C9303.495,426.699,9273.495,417.705,9245.497,402.705z"/>
        <!-- jambe -->
        <path id="ico_4_jambe" fill="#442765" d="M9208.495,94.697c-3.994-18.994-25-72.998-45.996-120.996c-32.998-70-37.998-90-29.004-105.996
    c7.002-14.004,18.008-19.004,37.002-17.012c22.002,2.012,32.998,17.012,67.998,88.008c24.004,47.002,43.008,94.004,43.008,105.996
    c0.996,11.006-14.004,35-33.008,53.008l-32.998,31.992L9208.495,94.697z"/>
        <!-- fleche -->
        <path id="ico_4_fleche" fill="#442765" d="M9722.499,198.701c-12.998-7.998-12.998-12.998,2.998-39.004l17.998-30l-117.998-2.998
    c-110-2.998-117.998-3.994-117.998-22.998s7.998-20,119.004-25c102.998-4.004,116.992-7.002,102.998-19.004
    c-21.006-16.992-22.002-46.992-1.006-55c16.006-5.996,114.004,78.008,114.004,98.008c0,10.996-90,105.996-100,105.996
    C9739.501,208.701,9730.497,203.701,9722.499,198.701z"/>
      <!--++++++++++++++++++-->
      <!-- ligne            -->
      <!--++++++++++++++++++-->
      <!-- ligne -->
      <rect id="line" x="0" y="-740" fill="#7C84B5" width="10400.11px" height="50"/>
      <!-- rect 1 -->
      <rect id="rect_1" x="1400" y="-715" fill="#7C84B5" width="80" height="200"/>
      <!-- rect 2 -->
      <rect id="rect_2" x="4100" y="-715" fill="#7C84B5" width="80" height="200"/>
      <!-- rect 3 -->
      <rect id="rect_3" x="6720.537" y="-715" fill="#7C84B5" width="80" height="200"/>
      <!-- rect 4 -->
      <rect id="rect_4" x="9332.147" y="-715" fill="#7C84B5" width="80" height="200"/>
      <!-- fleche -->
      <polygon id="triangle" fill="#7C84B5" points="10403.446,-782.021 10403.446,-841.514 10539.345,-722.529
    10403.446,-603.496 10403.446,-663.008"/>
    </g>
            <!-- Textes -->
            <g id="pouceTitre">
    <text x="12.5%" y="40px" text-anchor="middle" stroke-width="2px" dy=".3em" font-family="Viga Regular, sans-serif" style="fill:#7C84B5; font-size: 300%;">
    Pouce !
    </text>
    </g>
            <g id="pouceTexte1">
    <text x="12.5%" y="90%" text-anchor="middle" stroke-width="2px" dy=".3em" font-family="Viga Regular, sans-serif" style="fill:#442765; font-size: 2rem;">
    1 - Se pauser
    </text>
    </g>
            <g id="imagineTitre">
    <text x="38%" y="40px" text-anchor="middle" stroke-width="2px" dy=".3em" font-family="Viga Regular, sans-serif" style="fill:#7C84B5; font-size: 300%;">
    Imagine
    </text>
    </g>
            <g id="imagineTexte1">
    <text x="38%" y="90%" text-anchor="middle" stroke-width="2px" dy=".3em" font-family="Viga Regular, sans-serif" style="fill:#442765; font-size: 2rem;">
    2 - Se rêver
    </text>
    </g>
            <g id="strategieTitre">
    <text x="65%" y="40px" text-anchor="middle" stroke-width="2px" dy=".3em" font-family="Viga Regular, sans-serif" style="fill:#7C84B5; font-size: 300%;">
    Stratégie
    </text>
    </g>
            <g id="strategieTexte1">
    <text x="65%" y="90%" text-anchor="middle" stroke-width="2px" dy=".3em" font-family="Viga Regular, sans-serif" style="fill:#442765; font-size: 2rem;">
    3 - Se préparer
    </text>
    </g>
            <g id="actionTitre">
    <text x="87%" y="40px" text-anchor="middle" stroke-width="2px" dy=".3em" font-family="Viga Regular, sans-serif" style="fill:#7C84B5; font-size: 300%;">
    Action
    </text>
    </g>
            <g id="actionTexte1">
    <text x="87%" y="90%" text-anchor="middle" stroke-width="2px" dy=".3em" font-family="Viga Regular, sans-serif" style="fill:#442765; font-size: 2rem;">
    4 - Se lancer
    </text>
    </g>
    </svg>

        </div>
        <!-- Pourquoi -->
        <div class="bleu p3">
          <p>Vous ressentez un besoin de changement. Mais changer pour <strong>quoi ? Comment ? Quand ? </strong></p>
          <p>Ce parcours de coaching <strong>spécifique</strong> vous aide à répondre à ces questions, successivement ou séparement selon vos besoins.</p>
          <p>Tout commence par une <strong>prise de contact (gratuite)</strong> pour appréhender votre contexte. La toute première séance est consacrée à la définition globale de votre projet. Puis chaque étape permet, avec des outils spécifiques, d'avancer dans sa réalisation.</p>
          <p>Vous choisissez les étapes qui vous concernent, vous maitrisez votre temps et votre budget.</p>
        </div>
      </div>


    <h2 class="bleu" style="text-align: center">Etapes</h2>
    <div class="flex wrap">
      <!-- block 1 -->
      <div class="col" style="margin: 0 auto">
        <h4 class="text-center titre">Pouce !</h4>
        <div class="svg-container">
          <svg class="svg-1" xmlns="http://www.w3.org/2000/svg"
               width="100%"
               viewBox="0 0 80.000000 70.000000"
               preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,70.000000) scale(0.100000,-0.100000)" fill="#442765" stroke="none">
              <path d="M210 565 l-85 -85 3 -128 3 -127 87 -88 87 -87 120 0 120 0 87 87 88 88 0 125 0 125 -88 88 -87 87 -125 0 -125 0 -85 -85z m409 -12 l81 -77 0 -120 0 -121 -82 -82 -83 -83 -110 0 -110 0 -87 87 -88 88 0 110 0 110 82 82 83 83 116 0 116 0 82 -77z"/>
              <path d="M241 536 l-73 -74 1 -108 2 -109 77 -77 77 -78 100 0 100 0 78 78 77 77 0 110 0 110 -72 72 -72 73 -111 0 -111 0 -73 -74z m351 -13 l68 -67 0 -100 0 -101 -72 -72 -72 -73 -93 0 -92 0 -70 67 -71 68 0 105 0 104 67 68 67 68 100 0 100 0 68 -67z"/>
              <path d="M382 558 c-7 -7 -12 -17 -12 -23 0 -5 -10 -15 -22 -20 -21 -9 -23 -17 -22 -78 l1 -67 -22 20 c-25 24 -53 26 -62 5 -3 -9 6 -41 19 -73 14 -31 33 -78 42 -105 24 -63 46 -77 127 -77 100 0 104 6 104 189 0 143 -1 146 -22 149 -15 2 -23 11 -25 30 -2 18 -10 28 -23 31 -11 2 -24 9 -28 17 -10 17 -39 18 -55 2z m38 -104 c0 -68 3 -95 12 -92 7 3 10 30 9 81 -2 64 0 77 13 77 13 0 16 -16 16 -96 0 -69 3 -95 12 -92 8 3 11 25 9 66 -2 51 0 62 13 62 14 0 16 -18 16 -122 0 -159 -6 -172 -82 -176 -32 -2 -66 2 -77 7 -11 6 -31 42 -46 83 -16 40 -35 85 -43 100 -9 15 -12 31 -8 35 10 10 56 -43 56 -63 0 -9 5 -14 11 -12 7 2 12 37 13 93 1 61 5 90 14 92 9 4 12 -16 12 -77 0 -56 4 -80 11 -78 8 3 11 37 10 106 -2 86 0 102 13 102 13 0 16 -16 16 -96z"/>
            </g>
          </svg>
        </div> <!-- fin svg-container -->
        <ul class="bleu">
          <li>Faire le point</li>
          <li>Comprendre ce qui génère l'envie de changement</li>
          <li>Explorer ses manques, ses besoins, ses valeurs, etc.</li>
        </ul>
      </div>
      <!-- block 2 -->
      <div class="col">
        <h4 class="text-center titre">Imagine</h4>
        <div class="svg-container">
          <svg class="svg-1" xmlns="http://www.w3.org/2000/svg"
               width="100%" viewBox="0 0 79.000000 79.000000"
               preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,79.000000) scale(0.100000,-0.100000)"
               fill="#442765" stroke="none">
              <path d="M156 741 c-3 -5 3 -20 13 -32 16 -20 23 -22 38 -13 10 6 19 21 21 33 3 18 -1 21 -32 21 -19 0 -37 -4 -40 -9z"/>
              <path d="M196 652 c-15 -14 -3 -51 23 -76 29 -28 30 -28 54 -10 34 25 51 62 37 79 -10 12 -103 18 -114 7z m77 -44 c-18 -18 -24 -19 -37 -8 -25 20 -19 30 21 30 l37 0 -21 -22z"/>
              <path d="M349 591 c-38 -39 -39 -87 -3 -130 21 -26 33 -31 69 -31 36 0 48 5 69 31 36 43 35 91 -3 130 -22 21 -39 29 -66 29 -27 0 -44 -8 -66 -29z"/>
              <path d="M135 496 c-30 -21 -12 -49 63 -100 l72 -50 0 -126 c0 -85 4 -130 12 -138 8 -8 53 -12 135 -12 111 0 123 2 133 19 5 11 10 73 10 139 l0 118 73 50 c57 39 72 54 72 74 0 42 -38 38 -116 -14 l-69 -46 -105 0 -106 0 -57 38 c-87 59 -97 63 -117 48z"/>
            </g>
          </svg>
        </div>
        <!-- fin svg-container -->
        <ul class="bleu">
          <li>Ré-apprendre à rêver</li>
          <li>Stimuler sa créativité</li>
          <li>Ecouter ses envies</li>
          <li>S'autoriser à régresser pour progresser</li>
        </ul>
      </div>
      <!-- Block 3 -->
      <div class="col">
        <h4 class="text-center titre">Stratégie</h4>
        <div class="svg-container">
          <svg class="svg-1" xmlns="http://www.w3.org/2000/svg"
               width="100%" viewBox="0 0 86.000000 79.000000"
               preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,79.000000) scale(0.100000,-0.100000)"
               fill="#442765" stroke="none">
              <path d="M602 738 c-17 -17 -15 -32 9 -54 27 -24 54 -8 54 32 0 24 -5 30 -25 32 -14 2 -31 -3 -38 -10z m46 -25 c-4 -22 -22 -20 -26 1 -2 10 3 16 13 16 10 0 15 -7 13 -17z"/>
              <path d="M327 726 c-179 -50 -269 -267 -177 -424 11 -19 18 -42 15 -52 -6 -20 10 -36 20 -20 3 5 23 -3 47 -21 90 -65 201 -73 300 -23 l50 25 69 -74 c37 -41 78 -77 89 -80 20 -5 50 15 50 35 0 6 -37 48 -82 94 -76 78 -80 85 -66 101 9 10 24 42 33 71 33 101 1 224 -77 299 -68 65 -182 94 -271 69z m96 -13 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m60 -39 c61 -21 129 -86 145 -140 27 -92 10 -189 -44 -253 -83 -97 -240 -106 -342 -21 l-23 19 33 56 c18 30 39 55 48 55 8 0 42 -7 75 -15 33 -8 66 -14 72 -15 7 0 33 39 58 88 37 73 45 83 51 65 7 -24 19 -30 28 -14 9 14 -23 81 -38 81 -27 0 -77 -23 -73 -34 5 -12 11 -12 39 -1 11 5 5 -16 -24 -72 -23 -43 -42 -80 -44 -82 -2 -2 -40 6 -85 17 l-81 20 -36 -59 c-21 -32 -40 -59 -44 -59 -3 0 -13 21 -22 47 -43 127 9 253 129 309 59 28 113 30 178 8z m267 -583 c0 -8 -37 21 -83 65 -60 57 -80 83 -73 92 7 10 28 -6 82 -65 40 -43 73 -84 74 -92z m-317 82 c-13 -2 -35 -2 -50 0 -16 2 -5 4 22 4 28 0 40 -2 28 -4z"/>
              <path d="M340 611 c0 -6 5 -13 11 -17 8 -5 6 -10 -5 -16 -9 -5 -14 -14 -10 -19 4 -6 11 -6 20 2 11 9 15 8 20 -5 10 -26 27 -19 20 9 -4 14 -2 25 4 25 5 0 10 5 10 11 0 6 -9 9 -20 6 -11 -3 -20 -1 -20 4 0 5 -7 9 -15 9 -8 0 -15 -4 -15 -9z"/>
              <path d="M194 495 c4 -14 2 -25 -4 -25 -5 0 -10 -5 -10 -11 0 -6 7 -8 16 -5 9 4 23 1 31 -5 18 -15 29 -4 14 15 -9 11 -8 15 4 20 25 9 17 27 -10 20 -15 -4 -25 -2 -25 5 0 6 -5 11 -11 11 -7 0 -9 -10 -5 -25z"/>
              <path d="M520 372 c0 -4 5 -13 12 -20 9 -9 7 -13 -5 -18 -26 -10 -20 -27 7 -20 15 4 26 1 28 -6 2 -6 10 -8 17 -4 9 6 9 11 0 22 -9 11 -8 15 5 20 22 8 9 26 -14 19 -10 -3 -21 -1 -25 5 -7 12 -25 14 -25 2z"/>
              <path d="M315 291 c3 -11 1 -22 -5 -26 -6 -3 -8 -11 -4 -16 4 -6 11 -6 19 1 9 8 15 8 20 0 11 -18 27 -11 20 10 -4 11 -2 20 4 20 6 0 11 5 11 11 0 6 -9 9 -20 6 -11 -3 -20 -1 -20 4 0 5 -7 9 -16 9 -11 0 -13 -6 -9 -19z"/>
            </g>
          </svg>
        </div>
        <!-- fin svg-container -->
        <ul class="bleu">
          <li>Investiguer les possibilités</li>
          <li>Choisir </li>
          <li>Prioriser</li>
          <li>Elaborer un plan d'action</li>
        </ul>
      </div>
      <!-- Block 4 -->
      <div class="col">
        <h4 class="text-center titre">Action</h4>
        <div class="svg-container">
          <svg class="svg-1" xmlns="http://www.w3.org/2000/svg"
               width="100%" viewBox="0 0 84.000000 84.000000"
               preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,84.000000) scale(0.100000,-0.100000)"
               fill="#442765" stroke="none">
              <path d="M310 773 c-53 -20 -67 -83 -27 -121 30 -29 62 -28 92 3 39 38 32 86
                                        -16 110 -15 8 -29 15 -31 14 -2 0 -10 -3 -18 -6z"/>
              <path d="M219 577 c-85 -64 -126 -157 -73 -165 17 -2 28 7 45 39 35 62 45 59
                                        41 -12 l-4 -62 66 -66 c62 -63 66 -70 82 -144 13 -57 23 -81 37 -89 17 -9 24
                                        -7 38 10 15 20 15 26 -1 95 -21 87 -38 122 -78 160 -28 27 -29 31 -20 75 15
                                        77 16 78 41 55 72 -64 117 -75 117 -28 0 16 -11 30 -33 44 -18 11 -52 43 -76
                                        71 -40 47 -45 50 -91 50 -39 0 -56 -6 -91 -33z"/>
              <path d="M670 391 c0 -11 5 -23 10 -26 27 -17 1 -25 -92 -27 -95 -3 -103 -4
                                        -103 -23 0 -19 8 -20 103 -23 l102 -3 -15 -23 c-14 -21 -14 -25 0 -36 14 -11
                                        22 -7 60 30 25 24 45 49 45 55 0 17 -77 95 -94 95 -9 0 -16 -8 -16 -19z"/>
              <path d="M210 309 c0 -12 -16 -56 -35 -99 -19 -43 -35 -87 -35 -98 0 -26 23
                                        -44 45 -36 16 7 95 162 95 188 0 13 -51 66 -63 66 -4 0 -7 -10 -7 -21z"/>
            </g>
          </svg>
        </div>
        <!-- fin svg-container -->
        <ul class="bleu">
          <li>Oser</li>
          <li>Se mobiliser </li>
          <li>Avancer par étape</li>
          <li>Se sentir soutenu(e)</li>
          <li>S'adapter, s'ajuster</li>
        </ul>
      </div>
    </div> <!-- fin flex wrap -->
    <!-------------------------------- fin DETAILS du parcours -->

    <div class="headerAlacarte">
      <h2 class="bleu">Lieu</h2>
      <div class="bullet">
        <ul class="bleu">
          <li class="bulletPoint">Dans mon cabinet du XIème</li>
          <li class="bulletPoint">Dans un lieu de votre choix</li>
          <li class="bulletPoint">Par vidéo-conférence</li>
        </ul>
      </div>
    </div>

    <!-- Footers -->
    <Footer/>
    <FooterFull/>
  </div> <!-- fin Particuliers -->
</template>




<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue';
import Footer from "@/components/Footer";
import FooterFull from "@/components/FooterFull";
import BackToTop from "@/components/BackToTop/BackToTop"
export default {
  name: 'Transition',
  components: {
    Navbar,
    BackToTop,
    Footer,
    FooterFull
  },
  metaInfo() {
    return {
      meta: [
        { name: 'description', content: 'Un coaching sur-mesure et structuré pour vous aider à écrire le prochain chapitre de votre vie.'}
      ]
    }
  },
  mounted() {
    this.$gtag.pageview({
      page_path: 'Particuliers/Transition'
    });
  },
}

</script>

<style scoped lang="scss">
.headerAlacarte {
  text-align: center;
}

.bulletPoint {
  text-align: justify;
}

.p3 {
  padding: 3%;
}

@media screen and (min-width: 768px) {
  #transitionParcours {
    width: 50% !important;
  }
}

@media screen and (min-width: 1024px) {
  #transitionParcours {
    width: 50% !important;
  }
}

/* ipad pro */
@media screen and (min-width: 1280px) {
  #transitionParcours {
    width: 60% !important;
  }
}

#pouceTitre, #pouceTexte1, #ico_1_tour_1, #ico_1_tour_2, #ico_1_main, #rect_1, #line,
#imagineTitre, #imagineTexte1, #ico_2_corps, #ico_2_tete, #ico_2_coeur_1, #ico_2_coeur_2, #rect_2,
#strategieTitre, #strategieTexte1, #ico_3_loupe, #ico_3_rond, #ico_3_star_1, #ico_3_star_2, #ico_3_star_3, #ico_3_star_4, #rect_3,
#actionTitre, #actionTexte1, #ico_4_tete, #ico_4_corps, #ico_4_jambe, #ico_4_fleche, #rect_4,
#triangle {
  animation: slideIn 750ms cubic-bezier(0.6, 0.95, 0.73, 1.19) both,
  fadeIn 150ms ease-in both;
  font-size: 0.9rem;
}

/* ANIMATION */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#pouceTitre { animation-delay: 250ms }
#ico_1_tour_1 { animation-delay: 300ms }
#ico_1_tour_2 { animation-delay: 350ms }
#ico_1_main { animation-delay: 400ms }
#line { animation-delay: 450ms }
#rect_1 { animation-delay: 500ms }
#pouceTexte1 { animation-delay: 550ms }

#imagineTitre { animation-delay: 600ms }
#ico_2_corps, #ico_2_tete { animation-delay: 650ms }
#ico_2_coeur_1 { animation-delay: 700ms }
#ico_2_coeur_2 { animation-delay: 750ms }
#rect_2 { animation-delay: 800ms }
#imagineTexte1 { animation-delay: 850ms }

#strategieTitre { animation-delay: 850ms }
#ico_3_loupe { animation-delay: 900ms }
#ico_3_rond { animation-delay: 950ms }
#ico_3_star_1, #ico_3_star_2, #ico_3_star_3, #ico_3_star_4  { animation-delay: 1000ms }
#rect_3 { animation-delay: 1050ms }
#strategieTexte1 { animation-delay: 1100ms }

#actionTitre { animation-delay: 1150ms }
#ico_4_tete, #ico_4_corps, #ico_4_jambe { animation-delay: 1200ms }
#ico_4_fleche { animation-delay: 1250ms }
#rect_4, #triangle { animation-delay: 1300ms }
#actionTexte1 { animation-delay: 1350ms }

/* parcours */
.svg-container
{
  display: flex;
  align-items: center;
  height: auto;
}

.svg-1 {
  margin: 0 auto;
  display: block;
  //z-index: 9999;
  max-width: 80px;
}

.titre {
  color: #7C84B5;
}

.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}
.col {
  flex-basis: 200px;
  /*flex-shrink: 1;*/
  flex-grow: 1;
}

.text-center {
  text-align: center !important;
}

</style>
