// ./components/Footer.vue
<template>
  <!-- Zone au dessus footer -->
  <div class="parent">
    <div>
      <a href="mailto:contact@valerie-peltier.com?subject=Valérie Peltier - Coaching et Conseil">
        <img class="imgFooter" src="../assets/footer/email_300x300.png" alt="Valérie Peltier Coaching et Conseil Email" />
        <p class="p_footer">Emailons-nous</p>
      </a>
    </div>
    <div>
      <a href="tel:+33788851785">
        <img class="imgFooter" src="../assets/footer/phone_300x300.png" alt="Valérie Peltier Coaching et Conseil Téléphone" />
        <p class="p_footer">Appelons-nous</p>
      </a>
    </div>
    <div>
      <a href="https://wa.me/33788851785">
        <img class="imgFooter" src="../assets/footer/whatsApp_300x300.png" alt="Valérie Peltier Coaching et Conseil Whats App" />
        <p class="p_footer">WhatsAppons-nous</p>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="css">
.parent {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: space-between;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  padding-bottom: 2%;
  padding-top: 2%;
  text-align: center;
}

.imgFooter {
  max-width:50%;
}

.p_footer {
  font-size: 0.8rem;
}

a {
  color: #818494;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .imgFooter {
    width: auto !important;
  }
}
</style>


